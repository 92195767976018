import React, { useState,useEffect } from 'react';
import '../App.css';
import AddDiscountCoupons from "../Create_event_details/AddDiscountCoupons";
import noCoupons from "../img/other/no-coupon.png";
import LoaderImg from '../img/logo/running.gif';
import axios from '../axios';
import { toast } from 'react-toastify';

const Coupons = (props) => {

    const [AddCoupons, setAddCoupons] = useState(false); 
    // console.log(props.NewFlag);
    const EventData = props.EventData ? props.EventData : [];
    // const CouponsDetailsAll = props.CouponsDetails ? props.CouponsDetails : [];

    const [DeleteCouponPopup, setDeleteCouponPopup] = useState(false);
    const [ConfirmCouponTicket, setConfirmCouponTicket] = useState(false);
    const [DeleteTicketId, setDeleteTicketId] = useState('');
    const [EditTicketId, setEditTicketId] = useState('');
    const [CommId, setCommId] = useState('');
    const [EventStatus, setEventStatus] = useState(false);
    const [ActionFlag, setActionFlag] = useState('');
    const [evntStatus, setevntStatus] = useState(''); 
    const [eventIndex, seteventIndex] = useState(''); 
    const [CouponsDetailsAll, setCouponsDetailsAll] = useState([]);  
    const [EventFlag, setEventFlag] = useState(false);
    const [CouponDetails, setCouponDetails] = useState([]);  
    const [CouponsPopup, setCouponsPopup] = useState(false);
    const [Loader, setLoader] = useState(false);
    const [PopUpEditId, setPopUpEditId] = useState('');
    
    const [DiscountCodeEdit, setDiscountCodeEdit] = useState('');
    const [DiscountCodeError, setDiscountCodeError] = useState('');
    const [DiscountCodeSuccess, setDiscountCodeSuccess] = useState('');

    // console.log(DiscountCodeEdit);
  
    const handleComm = (comm_id, operate) => {
       
        if (operate === 'edit') {
            //setEditTicketId(TicketId)
            setAddCoupons(true);
        }
        if (operate === "delete") {
            setDeleteCouponPopup(true);
        }
        if (operate === "change_status") {
            setDeleteCouponPopup(true);
        }
        setCouponsPopup(false);
    }

    const DeleteCoupon = () => {
        
        if (CommId !== "") {
          
            axios.post('delete_event_comm_faq',
                {
                    'event_id': localStorage.getItem("EventId"),
                    'event_comm_id': CommId,
                    'common_flag': 'coupon_delete'
                }
            )
            .then((response) => {
                props.setEventApiCall(true)
                toast.success(response.data.message)
                setConfirmCouponTicket(false)
                setCommId('');
                setPopUpEditId('');
            })
            .catch((error) => {
                console.log(error);
            })
        }
    }

    const ActionChangeStatus = () => {
        //alert('1');
        // console.log(eventIndex,evntStatus.target.checked);
        if(ActionFlag === 'change_status'){
           
            setEventFlag(true);  
            let EventArray = [...CouponsDetailsAll];
            EventArray[eventIndex]['coupon_status'] = !evntStatus;
            setCouponsDetailsAll(EventArray);  
        //    console.log(evntStatus);
        }
        setLoader(true);
        const payload = {
            coupon_id: CommId,
            coupon_status: evntStatus
        };
        //console.log(payload);
        axios.post("status_coupon",payload)
        .then((response) => {
          //console.log(response);
            if (response.status === 200) {
                setLoader(false);
                toast.success(response.data.message)
                setevntStatus(''); 
                seteventIndex('');
                props.setEventApiCall(true);
                setEventStatus(false)
            }
        })
        .catch((error) => {
            setLoader(false);
            console.log(error);
        })
    }
    
    useEffect(() => {
        window.scrollTo(0, 0);
        if(props.CouponsDetails && EventFlag === false){
            setLoader(true);
            setCouponsDetailsAll(props.CouponsDetails);
            //console.log('ss');
        }
        setLoader(false);
    }, [props.CouponsDetails,EventFlag]);


    useEffect(() => {
        if(props.TicketDivShowFlag === false){
            setAddCoupons(false);
        }
    }, [props.TicketDivShowFlag]);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === "Escape") {
                setCouponsPopup(false);
                setDiscountCodeSuccess('');
                setDiscountCodeError('');
            }
        };
        // Add event listener when the component mounts
        document.addEventListener("keydown", handleKeyPress);
        // Remove event listener when the component unmounts
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
      }, []);
   
    const handleCodeUpdate = (eventEditId, couponId) => {
        // console.log(eventEditId,couponId,DiscountCodeEdit);
        
        axios.post('add_edit_coupon_code',
            {
                'event_id': localStorage.getItem("EventId"),
                'event_comm_id': eventEditId,
                'coupon_id': couponId,
                'DiscountCodeEdit': DiscountCodeEdit
            }
        )
        .then((response) => {
        //    console.log(response);
            
            if(response.data.data === 1 || response.data.data === '1'){
                toast.success(response.data.message);
                setDiscountCodeSuccess(response.data.message);
                props.setEventApiCall(true);
                setCommId('');
                setPopUpEditId('');
                setDiscountCodeEdit('');
                setDiscountCodeError('');
            }else{
                toast.error(response.data.message);
                setDiscountCodeError(response.data.message);
                setDiscountCodeSuccess('');
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }
// console.log(DiscountCodeError);
    return (
        <>
            {Loader ? <div><div id='loader' style={{ backgroundImage: `url(${LoaderImg})` }}></div></div> : null}
            {
                DeleteCouponPopup && (

                    <div className="modal is-visible" data-animation="slideInUp">
                        <div className="modal-dialog quickview__main--wrapper">
                            <div className="quickview__inner">
                                <div className="row">
                                    <div className="login__section">
                                        <div className="login__section--inner">
                                            <div className="p-5">
                                                <div className="account__login--header mb-25">
                                                 { ActionFlag && ActionFlag === 'delete' ?
                                                    <p className="account__login--header__desc">Are you sure you want to delete this discount coupons?</p>
                                                  : 
                                                    <p className="account__login--header__desc">Are you sure to change status this discount coupons?</p>
                                                  }
                                                </div>
                                                <div className="d-flex gap-4">
                                                    <button className="account__login--btn secondary__btn mt-2" onClick={() => {
                                                        setDeleteCouponPopup(false);
                                                    }}>Cancel</button>
                                                    
                                                    { ActionFlag && ActionFlag === 'delete' ?
                                                        <button className="account__login--btn primary__btn mt-2" onClick={(e) => { setConfirmCouponTicket(true); setDeleteCouponPopup(false); DeleteCoupon() }}>Yes</button>
                                                    :
                                                        <button className="account__login--btn primary__btn mt-2" onClick={(e) => { setConfirmCouponTicket(true); setDeleteCouponPopup(false); ActionChangeStatus() }}>Yes</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            

            {AddCoupons === true ?
                <AddDiscountCoupons EditTicketId={EditTicketId} setAddCoupons={setAddCoupons} setEditTicketId={setEditTicketId} setEventApiCall={props.setEventApiCall} setTicketDivShowFlag={props.setTicketDivShowFlag} TicketDivShowFlag={props.TicketDivShowFlag} EventTypeData={props.EventTypeData} CommId={CommId} TicketsDetails={props.TicketsDetails} setLoader={setLoader} PopUpEditId={PopUpEditId} />
                :
                <div className="main checkout__mian">
                    {/* <form action="#"> */}
                    <div className="checkout__content--step section__shipping--address ">

                        <div className="mb-5">
                            <div className="section__header mb-4 d-flex align-items-center justify-content-between">
                                <h2 className="section__header--title h3">Discount Coupons</h2>
                                <div className="continue__shipping--btn secondary__btn border-radius-5" onClick={() => { setAddCoupons(true); setCommId(''); setPopUpEditId(''); }} style={{cursor:'pointer'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg> New Discount Coupon
                                </div>
                            </div>
                            <div className="row">
                                {CouponsDetailsAll && CouponsDetailsAll.length > 0
                                    ?
                                    CouponsDetailsAll.map((item, index) => {
                                        return (
                                            <>
                                                <div className=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-5 form-que" key={index}>
                                                    <div className="card w-100">
                                                    
                                                        <div className="row mx-3 w-100 input-field">
                                                            <div className="col-sm-12 text-left mb-3">
                                                                <div className="row mb-3">
                                                                <div className="col-sm-10">
                                                                    <h4>{item.discount_name}</h4>
                                                                </div>
                                                                <div className="col-sm-2">
                                                                    <div className="form-check form-switch d-flex align-items-center justify-content-end my-1">
                                                                    <input className="form-check-input d-flex justify-content-end" type="checkbox" role="switch"
                                                                    value={item.coupon_status}
                                                                    checked={item.coupon_status}
                                                                    onChange={(e) => setEventStatus(!EventStatus)} onClick={(e) => { setCommId(item.id); handleComm(item.id, 'change_status'); setActionFlag('change_status'); setevntStatus(item.coupon_status); seteventIndex(index);  }} />
                                                                    </div> 
                                                                </div>
                                                                </div>
                                                            </div>
                                                        
                                                            <div className="col-sm-3 mt-3">
                                                                <div className="">
                                                                <h4>{item.no_of_discount}</h4>
                                                                <small>No of Discounts</small>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-3 mt-3">
                                                                <h4>{item.discount_amt_per_type === "1" ? item.discount_percentage+'%' : '₹ '+item.discount_amount}</h4>
                                                                <small>Discount Value</small>
                                                            </div>
                                                            <div className="col-sm-3 mt-3">
                                                                <h4>{item.expired_date}</h4>
                                                                <small>Expiry Date</small>
                                                            </div>
                                                            {item.discount_coupon_flag === 0 ?
                                                            <div className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-12 col-xxl-3 mt-3 coupon-detail coupon-button-type">
                                                                <div className="coupon-button coupon-code d-flex C">
                                                                    <span className="code-text">{item.discount_code}</span>
                                                                </div>
                                                            </div>
                                                            :  
                                                            <div className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-12 col-xxl-3 mt-3 coupon-detail coupon-button-type" onClick={() => { setCouponDetails(item.multiple_coupon_details); setCouponsPopup(true); }}>
                                                                <div className="continue__shipping--btn secondary__btn border-radius-5" style={{cursor:'pointer'}}>View Coupons</div>
                                                            </div>
                                                             
                                                            }
                                                        </div>

                                                        <div className="mx-3 option-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="delete-icon" height="24" viewBox="0 -960 960 960" width="24" onClick={() => { setCommId(item.id); handleComm(item.id, 'delete'); setActionFlag('delete'); }}><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" /></svg>
                                                        </div>

                                                        <div className="mx-3 option-icon-edit">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="delete-icon" height="24" viewBox="0 -960 960 960" width="24" onClick={() => { setCommId(item.id); setPopUpEditId(''); handleComm(item.id, 'edit');  }}><path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" /></svg>
                                                        </div>

                                                    </div>
                                                    </div>
                                            </>
                                        );
                                    })
                                    :

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 py-5 my-2 pb-0">
                                        <div className="w-100 not-avilable-card p-3 d-flex flex-column align-items-center">
                                            <img className=""  src={`${noCoupons}`} alt="" style={{ width: "130px" }} />
                                            <p className="section__header--title h4 mb-4">No Discount Coupons Added</p>
                                            <p className="h4 mb-4">Please click on " <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg> Add discount coupon" button to add new discount discount coupon.</p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    {/* is-visible  CouponDetails*/}
                     
                    { CouponsPopup === true ?
                    <div className="modal is-visible" data-animation="slideInUp" >
                        <div className="modal-dialog quickview__main--wrapper w-100">
                            <div className="quickview__inner">
                                <div className="row">
                                    <div className="login__section">
                                        <div className="login__section--inner" >
                                            <div className="p-3">
                                                <div className="account__login--header d-flex align-items-center gap-3 ">
                                                <img className="" src={`${noCoupons}`} alt="" style={{ width: "50px" }} />
                                                    <h3 className="account__login--header__title">Discount Coupons</h3>
                                                </div>
                                                <hr className="my-4"/>
                                                <div className="account__login--inner">
                                            
                                                <div className="row">
                                                { DiscountCodeError || DiscountCodeSuccess ?    
                                                    <div className= {DiscountCodeError ? 'alert alert-danger alert-dismissible fade show mb-4' : 'alert alert-success alert-dismissible fade show mb-4'}>
                                                       
                                                        {DiscountCodeError && DiscountCodeError !== '' ? DiscountCodeError : DiscountCodeSuccess}
                                                            <button
                                                            type="button"
                                                            className="btn-close"
                                                            data-bs-dismiss="alert"
                                                            aria-label="Close"
                                                            />
                                                    </div>
                                                : null }

                                                    {CouponDetails.map((item, index) => (
                                                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-5 form-que" key={index+2}>
                                                           <div className="card d-flex justify-content-center w-100 p-2">
                                                            {/* <h4>{item.name}</h4>  */}

                                                            <input
                                                                type="text"
                                                                className="form-control custom-card-input p-3 custom-card-input-1"
                                                                placeholder=""
                                                                defaultValue={item.name}  
                                                                onChange={(e) => {
                                                                    setDiscountCodeEdit(e.target.value); 
                                                                }}                    
                                                            />
                                                            
                                                                <div className="mx-3 option-icon-edit" style={{right:"-15px"}}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="delete-icon" height="24" viewBox="0 -960 960 960" width="24" onClick={() => { setCommId(item.edit_id); setPopUpEditId(item.id); handleCodeUpdate(item.edit_id,item.id);  }} ><path d="M840-680v480q0 33-23.5 56.5T760-120H200q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h480l160 160Zm-80 34L646-760H200v560h560v-446ZM480-240q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM240-560h360v-160H240v160Zm-40-86v446-560 114Z" /></svg>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>

                                                </div>

                                                <div className="d-flex gap-4 justify-content-end">
                                                    <button className="account__login--btn secondary__btn mt-2 w-auto" onClick={() => { setCouponsPopup(false); setCouponDetails([]); setDiscountCodeSuccess(''); setDiscountCodeError(''); }}>Close</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : '' }
                    

                    <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
                        <div className="continue__shipping--btn secondary__btn border-radius-5" onClick={(e) => { props.setEventResponceTabs(6) }} style={{cursor:'pointer'}} >Back</div>
                        <input type="submit" name="command" value="Save & Next (8/11)" className="continue__shipping--btn primary__btn border-radius-5" onClick={(e) => { props.setEventResponceTabs(8); }} />
                    </div>
                    {/* </form> */}
                </div>
            }
        </>
    )
}

export default Coupons;

