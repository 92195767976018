import React, { useState, useEffect } from 'react';
import '../App.css';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../axios';
import BreadcrumbBg from '../img/banner/breadcrumb-bg.jpg'
import HeaderSecond from '../HeaderSecond';


const CommonFooterPage = (props) => {
  // console.log("Create Event page");
  const navigate = useNavigate();
  const { PageId } = useParams();
  const [Title, setTitle] = useState('');
  const [Description, setDescription] = useState('');

  const handleBackToHome = () => {
    localStorage.removeItem('EventViewFlag');
    localStorage.removeItem('EventDetailsId');
  
    const CountryCode = localStorage.getItem('CountryCode');
    const CountryName = localStorage.getItem('Country');
    navigate('/' + CountryCode + '/' + CountryName);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
   //console.log(PageId);
   useEffect(() => {
    axios.post("cmsFooterPageDetails", { page_id :PageId })
    .then((response) => {
      // console.log(response.data.data);
      if(response.status === 200) {
        setTitle(response.data.data[0].title);
        setDescription(response.data.data[0].description);
      }
    })
    .catch((error) => {
      console.log('error');
    });  
  }, [PageId])

  return (
    <>
      <ToastContainer theme="colored" />

      <HeaderSecond />
      <main className="main__content_wrapper">
        {/* Start breadcrumb section */}
        <section className="breadcrumb__section breadcrumb__bg" style={{ backgroundImage: `url(${BreadcrumbBg})` }} >
          <div className="container">
            <div className="row row-cols-1">
              <div className="col">
                <div className="breadcrumb__content">
                  <h1 className="breadcrumb__content--title text-white mb-10">
                     {Title}
                  </h1>
                  <ul className="breadcrumb__content--menu d-flex">
                    <li className="breadcrumb__content--menu__items">
                      <div className="text-white" onClick={(e) => handleBackToHome(e)}>
                        Home
                      </div>
                    </li>
                    <li className="breadcrumb__content--menu__items">
                      <span className="text-white">{Title}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End breadcrumb section */}

        <section className="section--padding pt-5">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 my-5" style={{ textAlign: "justify" }}>
                 {/* {Description} */}
                 <p dangerouslySetInnerHTML={{__html:Description}}/>
              </div>
            </div>
          </div>
        </section>

      </main >

    </>
  )
}

export default CommonFooterPage;

