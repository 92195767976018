import React, { useEffect, useState } from "react";
import { Routes, Route,useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { token } from "./features/TokenSlice";
import axios from "./axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./Home";
import AddDeleteTableRows from "./Components/AddDeleteTableRows";
import MyLocation from "./Authorization/MyLocation";
import EventDetails from "./Event/EventDetails";
import SearchEvent from "./Event/SearchEvent";
import Profile from "./Profile/Profile";
import EventTypeSection from "./EventTypeSection";
import CreateEvent from "./Create_event_details/CreateEvent";
import EditEvent from "./Create_event_details/EditEvent";
import ShowWishlist from "./Wishlist/ShowWishlist";
import RegisterNow from "./Event/RegisterNow";
import TicketRegistration from "./Event/TicketRegistration";
import MyBookings from "./Event/MyBookings";
import MyBookingsDetails from "./Event/MyBookingsDetails";
import ViewOrganizer from "./Organizer/ViewOrganizer";
import AddressFetchComponent from "./AddressFetchComponent";
import OrganizerSettings from "./Organizer/OrganizerSettings";
import CopyEventDetails from "./Event/CopyEventDetails";
import GoogleLoginPage from "./GoogleLoginPage";
import LocationComponent from "./LocationComponent";
import Disclaimer from "./Footer/Disclaimer";
import TermsConditions from "./Footer/TermsConditions";
import PrivacyPolicy from "./Footer/PrivacyPolicy";
import CancellationPolicy from "./Footer/CancellationPolicy";
import ContactUs from "./Footer/ContactUs";
import AddressForm from "./AddressForm";
import EventDashbaord from "./Create_event_details/EventDashbaord";
import PaymentGateway from "./Event/PaymentGateway";
import EventDashbaordRegistration from "./Create_event_details/EventDashbaordRegistration";
import EventDashboardNetSales from "./Create_event_details/EventDashboardNetSales";
import EventTermsConditions from "./Event/EventTermsConditions";
import EventDashboardPaymentHistory from "./Create_event_details/EventDashboardPaymentHistory";
import HowItWorks from "./Footer/HowItWorks";
import ResetPassword from "./Authorization/ResetPassword";
import CommonFooterPage from "./Footer/CommonFooterPage";
import MaintenanceMode from "./MaintenanceMode"; 
// import EventEmbeddVist from "./Event/EventEmbeddVist";

function App() {
  const dispatch = useDispatch();

  const [error, setError] = useState("");
   const navigate = useNavigate();
  const [Showmaintancemode, setShowmaintancemode] = useState(null);
  useEffect(() => {
    if (error) {
      toast.error(error);
      setError("");
    }
    return () => { };
  }, [error]);

  useEffect(() => {
    if (localStorage.getItem("RacesToken")) {
      dispatch(token(JSON.parse(localStorage.getItem("RacesToken"))));
    }
  }, [dispatch]);

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  ); 

  useEffect(() => {
    if (token) {
      axios.get("get_maintancemode")
        .then((response) => {
          const mode = response.data.data.maintance_mode[0].maintenance_mode;
          setShowmaintancemode(mode);

          if (mode !== 0) {
            navigate("/maintenance_mode");
          }
        })
        .catch((error) => {
          console.error("Error fetching maintenance mode:", error);
        });
    }
  }, [token, navigate]);

  const tokenValue = useSelector((state) => state.token.value);
  const maintancemode = Showmaintancemode;
  
  var paths = null;
    if(maintancemode == 0){
      if (tokenValue.token) {
        paths = (
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route exact path="/:country_code/:country" element={<Home />}></Route>
            <Route exact path="/carousels" element={<AddDeleteTableRows />}></Route>
            <Route exact path="/my_location" element={<MyLocation />}></Route>
            <Route exact path="/event_details" element={<EventDetails />}></Route>
            <Route exact path="/edit_event" element={<EditEvent />}></Route>
            <Route exact path="/events" element={<SearchEvent />}></Route>
            <Route exact path="/profile" element={<Profile />}></Route>
            <Route path="/events_selection" element={<EventTypeSection />}></Route>
            <Route path="/create_events" element={<CreateEvent />}></Route>
            <Route path="/wishlist" element={<ShowWishlist />}></Route>
            <Route exact path="/register_now/:EventId/:payment_status?" element={<RegisterNow />}></Route>
            <Route path="/ticket_registration/:EventId" element={<TicketRegistration />}></Route>
            <Route path="/event_terms_conditions/:EventId" element={<EventTermsConditions />}></Route>
            <Route path="/my_bookings" element={<MyBookings />}></Route>
            <Route path="/booking_details" element={<MyBookingsDetails />}></Route>
            <Route path="/o/:OrganiserName/:UserId" element={<ViewOrganizer />}></Route>
            <Route path="/address_fetch" element={<AddressFetchComponent />}></Route>
            <Route path="/organizer" element={<OrganizerSettings />}></Route>
            <Route exact path="/e/:event_name" element={<CopyEventDetails />}></Route>
            <Route path="/disclaimer" element={<Disclaimer />}></Route>
            <Route path="/terms_conditions" element={<TermsConditions />}></Route>
            <Route path="/privacy_policy" element={<PrivacyPolicy />}></Route>
            <Route path="/cancellation_policy" element={<CancellationPolicy />}></Route>
            <Route path="/contact_us" element={<ContactUs />}></Route>
            <Route path="/map" element={<AddressForm />}></Route>
            <Route path="/event_dashboard" element={<EventDashbaord />}></Route>        
            <Route path="/payment_gateway/:Status?" element={<PaymentGateway />}></Route>
            <Route path="/event_dashboard/:EventId" element={<EventDashbaord />}></Route>
            <Route path="/event_registrations/:EventId" element={<EventDashbaordRegistration />}></Route>
            <Route path="/event_netsale/:EventId/:CouponUsedFlag?" element={<EventDashboardNetSales />}></Route>
            <Route path="/payment_history/:EventId" element={<EventDashboardPaymentHistory />}></Route>
            <Route path="/how_it_works" element={<HowItWorks />}></Route>
            <Route path="/common_footer_page/:PageId" element={<CommonFooterPage />}></Route>
            {/* <Route exact path="/events_embedd_vist/:EventId" element={<EventEmbeddVist />}></Route> */}
            <Route exact path="/:country_code/:country/:login_as_org?/:user_email?/:user_pass?" element={<Home />}></Route>
          </Routes>
        );
      } else {
        paths = (
          <Routes>
            <Route exact path="/home/:reset_password?/:reset_password_token?" element={<ResetPassword />}></Route>
            {/* <Route exact path="/:country_code/:country" element={<Home />}></Route> */}
            <Route exact path="/:country_code/:country/:login_as_org?/:user_email?/:user_pass?" element={<Home />}></Route>
            <Route exact path="/" element={<Home />}></Route>
            <Route exact path="/my_location" element={<MyLocation />}></Route>
            <Route exact path="/events" element={<SearchEvent />}></Route>
            <Route exact path="/e/:event_name" element={<CopyEventDetails />}></Route>
            <Route exact path="/event_details" element={<EventDetails />}></Route>
            <Route exact path="/google_login" element={<GoogleLoginPage />}></Route>
            <Route exact path="/google_success" element={<Home />}></Route>
            <Route exact path="/location_pincode" element={<LocationComponent />}></Route>
            <Route path="/disclaimer" element={<Disclaimer />}></Route>
            <Route path="/terms_conditions" element={<TermsConditions />}></Route>
            <Route path="/privacy_policy" element={<PrivacyPolicy />}></Route>
            <Route path="/cancellation_policy" element={<CancellationPolicy />}></Route>
            <Route path="/contact_us" element={<ContactUs />}></Route>
            <Route path="/event_dashboard" element={<EventDashbaord />}></Route>
            <Route path="/how_it_works" element={<HowItWorks />}></Route>
            <Route path="/common_footer_page/:PageId" element={<CommonFooterPage />}></Route>
            {/* <Route exact path="/events_embedd_vist/:EventId" element={<EventEmbeddVist />}></Route> */}
          </Routes>
        );
      }
    }else{
      // console.log("jdsifjoiejsojfeoidois");
      paths = (
        <Routes>
          <Route exact path="/maintenance_mode" element={<MaintenanceMode />} />
        </Routes>
      );
    }
  return (
    <div>
      {paths}
    </div>
  );
}

export default App;



// Client ID
// 300373314303-h4p19miaa7u1nsr8li4qqkdva8vobf3b.apps.googleusercontent.com

// Client secret
// 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