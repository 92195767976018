import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import HeaderSecond from '../HeaderSecond'
import BreadcrumbBg from '../img/banner/breadcrumb-bg.jpg'
import axios from '../axios';
import EventView from '../img/events/event-view.jpg'
import NoEventImg from '../img/events/no-events.png'

const MyBookings = () => {
    const navigate = useNavigate();
    const [pastEvents, setpastEvents] = useState([]);
    const [activeEvents, setactiveEvents] = useState([]);


    useEffect(() => {
        // console.log(localStorage.getItem("ID"));
        const payload = {}
        axios.post("get_bookings", payload)
            .then((response) => {
                // console.log(response.data.data.activeEvents);
                setpastEvents(response.data.data.pastEvents);
                setactiveEvents(response.data.data.activeEvents);
            })
            .catch((error) => {
                console.log(error);
            })
    }, [])
// console.log(pastEvents)
    const BackToHome = () => {
        // localStorage.removeItem('EventViewFlag');
        // localStorage.removeItem('EventDetailsId');
        localStorage.removeItem('EventId');
        localStorage.removeItem('EventType');
        const CountryCode = localStorage.getItem('CountryCode');
        const CountryName = localStorage.getItem('Country');
        navigate('/' + CountryCode + '/' + CountryName);
    }

    const ViewDetails = (EventId) => {
        navigate("/booking_details", { state: { "event_id": EventId } })
    }

    return (
        <>
            <HeaderSecond />
            <main className="main__content_wrapper">
                <section className="breadcrumb__section breadcrumb__bg" style={{ backgroundImage: `url(${BreadcrumbBg})` }} >
                    <div className="container">
                        <div className="row row-cols-1">
                            <div className="col">
                                <div className="breadcrumb__content">
                                    <h1 className="breadcrumb__content--title text-white mb-10">
                                    Registration Tracker
                                    </h1>
                                    <ul className="breadcrumb__content--menu d-flex">

                                        <li className="breadcrumb__content--menu__items" onClick={(e) => BackToHome()}>
                                            <div className="text-white">
                                                Home
                                            </div>
                                        </li>

                                        <li className="breadcrumb__content--menu__items">
                                            <span className="text-white">Registration Tracker</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-5">
                    <div className="container">
                        <div className="checkout__content--step section__shipping--address ">
                            <div className="section__header checkout__section--header d-flex align-items-center justify-content-between mb-4">
                                <h2 className="section__header--title h3">Upcoming Events</h2>
                            </div>
                        </div>
                        <div className="row my-booking">
                            {
                                activeEvents && activeEvents.length > 0 ?
                                    activeEvents.map((event, eventIndex) => (
                                        <React.Fragment key={`${eventIndex}-${event.EventName}`}>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 mb-20">
                                                <div className="row coupon">
                                                    <div className="col-12 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1 left">
                                                    </div>

                                                    <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                                                        <div className="row w-100 resp-mx-5">
                                                            <div className="col-sm-4 d-flex align-items-center justify-content-center p-0" style={{ backgroundImage: `url('${event.banner_image || EventView}')`, backdropFilter: 'blur(50px)', borderRadius: " 10px" }}>
                                                                <div className="mb-event-img-bg">
                                                                    <img src={`${event.banner_image || EventView}`} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-8">
                                                                <h4 className="pb-2 event-title" style={{height: '55px', overflow: 'hidden'}} >{event.EventName}</h4>
                                                                <p className="m-1"><svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M580-240q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Z" /></svg> {event.start_date} </p>
                                                                <p className="m-1"><svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="m612-292 56-56-148-148v-184h-80v216l172 172ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z" /></svg> {event.start_time_event}</p>
                                                                <p className="m-1"><svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z" /></svg> {event.city_name}</p>
                                                                <p className="pt-2">{event.TotalCount}{" "}{event.TotalCount > 1 ? "Registrations" : "Registration"}  | <small className="vd" onClick={(e) => ViewDetails(event.event_id)}>View Details</small></p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1 right">
                                                        <div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )) :

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-center py-5">
                                        <img src={NoEventImg} alt="" className="mb-4" style={{ width: "250px" }} />
                                        <h4>No registration booked yet.</h4>
                                    </div>
                            }
                        </div>
                    </div>


                    <div className="container">
                        <hr />
                        <div className="checkout__content--step section__shipping--address ">
                            <div className="section__header checkout__section--header d-flex align-items-center justify-content-between mb-4">
                                <h2 className="section__header--title h3">Past Events</h2>
                            </div>
                        </div>
                        <div className="row my-booking">
                            {
                                pastEvents && pastEvents.length > 0 ?
                                    pastEvents.map((event, eventIndex) => (
                                        <React.Fragment key={`${eventIndex}-${event.EventName}`}>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 mb-20">
                                                <div className="row coupon">
                                                    <div className="col-12 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1 left">
                                                    </div>

                                                    <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                                                        <div className="row w-100">
                                                            <div className="col-sm-4 d-flex align-items-center justify-content-center p-0" style={{ backgroundImage: `url('${event.banner_image || EventView}')`, backdropFilter: 'blur(50px)', borderRadius: " 10px" }}>
                                                                <div className="mb-event-img-bg">
                                                                    <img src={`${event.banner_image || EventView}`} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-8">
                                                                <h4 className="pb-2 event-title" style={{height: '55px', overflow: 'hidden'}} >{event.EventName}</h4>
                                                                <p className="m-1"><svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M580-240q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Z" /></svg> {event.start_date} </p>
                                                                <p className="m-1"><svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="m612-292 56-56-148-148v-184h-80v216l172 172ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z" /></svg> {event.start_time_event}</p>
                                                                <p className="m-1"><svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z" /></svg> {event.city_name}</p>
                                                                <p className="pt-2">{event.TotalCount}{" "}{event.TotalCount > 1 ? "Registrations" : "Registration"} | <small className="vd" onClick={(e) => ViewDetails(event.event_id)}>View Details</small></p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1 right">
                                                        <div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )) :

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-center py-5">
                                        <img src={NoEventImg} alt="" className="mb-4" style={{ width: "250px" }} />
                                        <h4>No registration booked yet.</h4>
                                    </div>
                            }
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default MyBookings