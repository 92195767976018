import React, { useState, useEffect } from 'react';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import event_card_img from '../img/events/event-view.jpg';
import BasicInformation from "../Create_event_details/BasicInformation";
import EventDuration from "../Create_event_details/EventDuration";
import EventDescription from "../Create_event_details/EventDescription";
import Tickets from "../Create_event_details/Tickets";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../axios';
import BreadcrumbBg from '../img/banner/breadcrumb-bg.jpg'
import HeaderSecond from '../HeaderSecond';
import FormQuestions from './FormQuestions';
import Integration from './Integration';
import Settings from './Settings';
import Communication from "../Create_event_details/Communication";
import EventFAQ from "../Create_event_details/EventFAQ";
import Coupons from "../Create_event_details/Coupons";
import AgeCriteria from "../Create_event_details/AgeCriteria";
import {UserAccessRights} from '../Custom';


import Loader from '../Loader';

const CreateEvent = () => {
  // console.log("Create Event page");
  const navigate = useNavigate();

  const [EventTabs, setEventTabs] = useState(1);
  const [EventTabsClass1, setEventTabsClass1] = useState('');
  const [EventTabsClass2, setEventTabsClass2] = useState('');
  const [EventTabsClass3, setEventTabsClass3] = useState('');
  const [EventTabsClass4, setEventTabsClass4] = useState('');
  const [EventTabsClass5, setEventTabsClass5] = useState('');
  const [EventTabsClass6, setEventTabsClass6] = useState('');
  const [EventTabsClass7, setEventTabsClass7] = useState('');
  const [EventTabsClass8, setEventTabsClass8] = useState('');
  const [EventTabsClass9, setEventTabsClass9] = useState('');
  const [EventTabsClass10, setEventTabsClass10] = useState('');
  const [EventTabsClass11, setEventTabsClass11] = useState('');

  //console.log(BasicInfoFlag);
  const [EventResponceTabs, setEventResponceTabs] = useState(0);
  const [EventBasicInfoMsg, setEventBasicInfoMsg] = useState('');
  const [EventDurationMsg, setEventDurationMsg] = useState('');
  const [EventDescriptionMsg, setEventDescriptionMsg] = useState('');
  const [EventDetailsAll, setEventDetailsAll] = useState([]);
  const [EventTicketsAll, setEventTicketsAll] = useState([]);
  const [EventImagesAll, setEventImagesAll] = useState([]);
  const [EventCategoryAll, setEventCategoryAll] = useState([]);
  const [EventTypeAll, setEventTypeAll] = useState([]);
  const [EventCardPreview, setEventCardPreview] = useState([]);
  const [EventApiCall, setEventApiCall] = useState(true);
  const [DivClass, setDivClass] = useState('col-lg-8 col-md-6');
  const [TicketDivShowFlag, setTicketDivShowFlag] = useState(false);
  const [EventSettingDetails, setEventSettingDetails] = useState([]);
  const [FieldMappingDetails, setFieldMappingDetails] = useState([]);
  const [CommunicationDetails, setCommunicationDetails] = useState([]);
  const [FAQDetails, setFAQDetails] = useState([]);
  const [TicketsDetails, setTicketsDetails] = useState([]);
  const [CouponsDetails, setCouponsDetails] = useState([]);
  const [AgeCriteriaDetails, setAgeCriteriaDetails] = useState([]);
  const [AgeDetails, setAgeDetails] = useState([]);
  const [TermConditionsDetails, setTermConditionsDetails] = useState([]);
  const [EventName, setEventName] = useState('');
  const [FormTicketsDetails, setFormTicketsDetails] = useState([]);
  const [CouponTicketsDetails, setCouponTicketsDetails] = useState([]);
  const [RaceCategoryChargesDetails, setRaceCategoryChargesDetails] = useState([]);
  
 
  const EventId = localStorage.getItem("EventId");
  const [YtcrFeePercent, setYtcrFeePercent] = useState('');
  const [PlatformFeePercent, setPlatformFeePercent] = useState('');
  const [PaymentGatewayFeePercent, setPaymentGatewayFeePercent] = useState('');
  const [PaymentGatewayGstPercent, setPaymentGatewayGstPercent] = useState('');
  const [loader, setLoader] = useState(false)

  const [NewFlag, setNewFlag] = useState(0);
  const [TabClickFlag, setTabClickFlag] = useState(true);
  const [EventTcsPopupFlag, setEventTcsPopupFlag] = useState(0);
  const [EventDispName, setEventDispName] = useState('');

  

  // console.log(EventDetailsAll);
  //console.log(YtcrFeePercent);

  const handleBackToHome = () => {
    localStorage.removeItem('EventViewFlag');
    localStorage.removeItem('EventDetailsId');

    const CountryCode = localStorage.getItem('CountryCode');
    const CountryName = localStorage.getItem('Country');
    navigate('/' + CountryCode + '/' + CountryName);
  }

  //console.log(EventCardPreview);
  const common_svg = "M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z";

  const [EventSvg1, setEventSvg1] = useState("m422-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z");

  const green_tick_svg = "m422-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z";

  const [EventSvg2, setEventSvg2] = useState(common_svg);

  const [EventSvg3, setEventSvg3] = useState(common_svg);

  const [EventSvg4, setEventSvg4] = useState(common_svg);

  const [EventSvg5, setEventSvg5] = useState(common_svg);

  const [EventSvg6, setEventSvg6] = useState(common_svg);

  const [EventSvg7, setEventSvg7] = useState(common_svg);

  const [EventSvg8, setEventSvg8] = useState(common_svg);

  const [EventSvg9, setEventSvg9] = useState(common_svg);

  const [EventSvg10, setEventSvg10] = useState(common_svg);

  const [EventSvg11, setEventSvg11] = useState(common_svg);

 
  // console.log(EventResponceTabs);

  useEffect(() => {
   
    //localStorage.setItem("EventId", 50);
    if (EventResponceTabs === 0) {  // basic info
      setEventTabs(1); 
    } else if (EventResponceTabs === 1) {   // event duration
      setEventTabs(2);
    } else if (EventResponceTabs === 2) { // event discription
      setEventTabs(3);
    } else if (EventResponceTabs === 3) { // setting
      setEventTabs(4);
    } else if (EventResponceTabs === 4) { // tickets
      setEventTabs(5);
    } else if (EventResponceTabs === 5) { // form questions
      setEventTabs(6);
    } else if (EventResponceTabs === 6) { // integration
      setEventTabs(7);
    } else if (EventResponceTabs === 7) { // communication
      setEventTabs(8);
    } else if (EventResponceTabs === 8) { // FAQ
      setEventTabs(9);
    } else if (EventResponceTabs === 9) { // FAQ
      setEventTabs(10);
    } else if (EventResponceTabs === 10) { // FAQ
      setEventTabs(11);
    } 
    setEventApiCall(true)

  }, [EventBasicInfoMsg, EventDescriptionMsg, EventDurationMsg, EventResponceTabs])

  const mainUrlLink = window.location.origin;
  //------------- get event details ----------------
  useEffect(() => {
    if (EventApiCall && !isNaN(EventId)) {
    //  console.log(EventResponceTabs,EventTabs);
      if(EventTabs === 1){
        setEventTabsClass1('active'); setEventTabsClass2(''); setEventTabsClass3(''); setEventTabsClass4(''); 
        setEventTabsClass5(''); setEventTabsClass6(''); setEventTabsClass7(''); setEventTabsClass8('');
        setEventTabsClass9(''); setEventTabsClass10(''); setEventTabsClass11('');
        setEventSvg2(common_svg); setEventSvg3(common_svg); setEventSvg4(common_svg); setEventSvg5(common_svg); 
        setEventSvg6(common_svg); setEventSvg7(common_svg); setEventSvg8(common_svg); setEventSvg9(common_svg); 
        setEventSvg10(common_svg);setEventSvg11(common_svg); setEventSvg1(green_tick_svg);
      }else if(EventTabs === 2){
        setEventTabsClass1(''); setEventTabsClass2('active'); setEventTabsClass3(''); setEventTabsClass4(''); 
        setEventTabsClass5(''); setEventTabsClass6(''); setEventTabsClass7(''); setEventTabsClass8('');
        setEventTabsClass9(''); setEventTabsClass10(''); setEventTabsClass11('');
        setEventSvg1(common_svg); setEventSvg3(common_svg); setEventSvg4(common_svg); setEventSvg5(common_svg); 
        setEventSvg6(common_svg); setEventSvg7(common_svg); setEventSvg8(common_svg); setEventSvg9(common_svg); 
        setEventSvg10(common_svg);setEventSvg11(common_svg); setEventSvg2(green_tick_svg);
      }else if(EventTabs === 3){
        setEventTabsClass1(''); setEventTabsClass2(''); setEventTabsClass3('active'); setEventTabsClass4(''); 
        setEventTabsClass5(''); setEventTabsClass6(''); setEventTabsClass7(''); setEventTabsClass8('');
        setEventTabsClass9(''); setEventTabsClass10(''); setEventTabsClass11('');
        setEventSvg1(common_svg); setEventSvg2(common_svg); setEventSvg4(common_svg); setEventSvg5(common_svg); 
        setEventSvg6(common_svg); setEventSvg7(common_svg); setEventSvg8(common_svg); setEventSvg9(common_svg); 
        setEventSvg10(common_svg);setEventSvg11(common_svg); setEventSvg3(green_tick_svg);
      }else if(EventTabs === 4){
        setEventTabsClass1(''); setEventTabsClass2(''); setEventTabsClass3(''); setEventTabsClass4('active'); 
        setEventTabsClass5(''); setEventTabsClass6(''); setEventTabsClass7(''); setEventTabsClass8('');
        setEventTabsClass9(''); setEventTabsClass10(''); setEventTabsClass11('');
        setEventSvg1(common_svg); setEventSvg2(common_svg); setEventSvg3(common_svg); setEventSvg5(common_svg); 
        setEventSvg6(common_svg); setEventSvg7(common_svg); setEventSvg8(common_svg); setEventSvg9(common_svg); 
        setEventSvg10(common_svg);setEventSvg11(common_svg); setEventSvg4(green_tick_svg);
      }else if(EventTabs === 5){
        setEventTabsClass1(''); setEventTabsClass2(''); setEventTabsClass3(''); setEventTabsClass4(''); 
        setEventTabsClass5('active'); setEventTabsClass6(''); setEventTabsClass7(''); setEventTabsClass8('');
        setEventTabsClass9(''); setEventTabsClass10(''); setEventTabsClass11('');
        setEventSvg1(common_svg); setEventSvg2(common_svg); setEventSvg3(common_svg); setEventSvg4(common_svg); 
        setEventSvg6(common_svg); setEventSvg7(common_svg); setEventSvg8(common_svg); setEventSvg9(common_svg); 
        setEventSvg10(common_svg);setEventSvg11(common_svg); setEventSvg5(green_tick_svg);
      }else if(EventTabs === 6){
        setEventTabsClass1(''); setEventTabsClass2(''); setEventTabsClass3(''); setEventTabsClass4(''); 
        setEventTabsClass5(''); setEventTabsClass6('active'); setEventTabsClass7(''); setEventTabsClass8('');
        setEventTabsClass9(''); setEventTabsClass10(''); setEventTabsClass11('');
        setEventSvg1(common_svg); setEventSvg2(common_svg); setEventSvg3(common_svg); setEventSvg4(common_svg); 
        setEventSvg5(common_svg); setEventSvg7(common_svg); setEventSvg8(common_svg); setEventSvg9(common_svg); 
        setEventSvg10(common_svg);setEventSvg11(common_svg); setEventSvg6(green_tick_svg);
      }else if(EventTabs === 7){
        setEventTabsClass1(''); setEventTabsClass2(''); setEventTabsClass3(''); setEventTabsClass4(''); 
        setEventTabsClass5(''); setEventTabsClass6(''); setEventTabsClass7('active'); setEventTabsClass8('');
        setEventTabsClass9(''); setEventTabsClass10(''); setEventTabsClass11('');
        setEventSvg1(common_svg); setEventSvg2(common_svg); setEventSvg3(common_svg); setEventSvg4(common_svg); 
        setEventSvg5(common_svg); setEventSvg6(common_svg); setEventSvg8(common_svg); setEventSvg9(common_svg); 
        setEventSvg10(common_svg);setEventSvg11(common_svg); setEventSvg7(green_tick_svg);
      }else if(EventTabs === 8){
        setEventTabsClass1(''); setEventTabsClass2(''); setEventTabsClass3(''); setEventTabsClass4(''); 
        setEventTabsClass5(''); setEventTabsClass6(''); setEventTabsClass7(''); setEventTabsClass8('active');
        setEventTabsClass9(''); setEventTabsClass10(''); setEventTabsClass11('');
        setEventSvg1(common_svg); setEventSvg2(common_svg); setEventSvg3(common_svg); setEventSvg4(common_svg); 
        setEventSvg5(common_svg); setEventSvg6(common_svg); setEventSvg7(common_svg); setEventSvg9(common_svg); 
        setEventSvg10(common_svg);setEventSvg11(common_svg); setEventSvg8(green_tick_svg);
      }else if(EventTabs === 9){
        setEventTabsClass1(''); setEventTabsClass2(''); setEventTabsClass3(''); setEventTabsClass4(''); 
        setEventTabsClass5(''); setEventTabsClass6(''); setEventTabsClass7(''); setEventTabsClass8('');
        setEventTabsClass9('active'); setEventTabsClass10(''); setEventTabsClass11('');
        setEventSvg1(common_svg); setEventSvg2(common_svg); setEventSvg3(common_svg); setEventSvg4(common_svg); 
        setEventSvg5(common_svg); setEventSvg6(common_svg); setEventSvg7(common_svg); setEventSvg8(common_svg); 
        setEventSvg10(common_svg);setEventSvg11(common_svg); setEventSvg9(green_tick_svg);
      }else if(EventTabs === 10){
        setEventTabsClass1(''); setEventTabsClass2(''); setEventTabsClass3(''); setEventTabsClass4(''); 
        setEventTabsClass5(''); setEventTabsClass6(''); setEventTabsClass7(''); setEventTabsClass8('');
        setEventTabsClass9(''); setEventTabsClass10('active'); setEventTabsClass11('');
        setEventSvg1(common_svg); setEventSvg2(common_svg); setEventSvg3(common_svg); setEventSvg4(common_svg); 
        setEventSvg5(common_svg); setEventSvg6(common_svg); setEventSvg7(common_svg); setEventSvg8(common_svg); 
        setEventSvg9(common_svg);setEventSvg11(common_svg); setEventSvg10(green_tick_svg);
      }else if(EventTabs === 11){
        setEventTabsClass1(''); setEventTabsClass2(''); setEventTabsClass3(''); setEventTabsClass4(''); 
        setEventTabsClass5(''); setEventTabsClass6(''); setEventTabsClass7(''); setEventTabsClass8('');
        setEventTabsClass9(''); setEventTabsClass10(''); setEventTabsClass11('active');
        setEventSvg1(common_svg); setEventSvg2(common_svg); setEventSvg3(common_svg); setEventSvg4(common_svg); 
        setEventSvg5(common_svg); setEventSvg6(common_svg); setEventSvg7(common_svg); setEventSvg8(common_svg); 
        setEventSvg9(common_svg);setEventSvg10(common_svg); setEventSvg11(green_tick_svg);
      }else{
        setEventTabsClass1('');
      }
      //console.log(EventTabs);
      //-------------------------
      //setLoader(true);

      const payload = {
        event_id: EventId !== 0 ? EventId : 0,
        url_link: mainUrlLink
      };
      axios.post("event_details", payload)
        .then((response) => {
        //  console.log(response.data.data.GST_PERCENTAGE);
          if (response.data.status === 200) {
           //console.log(response.data.data.event_setting_details);
           setEventCategoryAll(response.data.data.AllCategory);
           setEventDetailsAll(response.data.data.EventData ? response.data.data.EventData[0] : []);
           
           setEventDispName(response.data.data.EventData[0] ? response.data.data.EventData[0].name : 'Design Your Event');
        
            setEventTcsPopupFlag(response.data.data.EventData[0] ? response.data.data.EventData[0].tcs_popup : 0);
            setEventTicketsAll(response.data.data.EventTickets ? response.data.data.EventTickets : []);
           
            setEventTypeAll(response.data.data.AllEventTypes ? response.data.data.AllEventTypes : [])
            setEventCardPreview(response.data.data.PreviewEventDetails ? response.data.data.PreviewEventDetails : []);
            setEventImagesAll(response.data.data.EventImages);
            setEventApiCall(false);
            setYtcrFeePercent(response.data.data.YTCR_FEE_PERCENTAGE);
            setPlatformFeePercent(response.data.data.GST_PERCENTAGE);
            setPaymentGatewayFeePercent(response.data.data.PAYMENT_GATEWAY_FEE_PERCENTAGE);
            setPaymentGatewayGstPercent(response.data.data.PAYMENT_GATEWAY_GST_PERCENTAGE);
            setEventSettingDetails(response.data.data.event_setting_details ? response.data.data.event_setting_details[0] : []);
            setFieldMappingDetails(response.data.data.field_mapping_details ? response.data.data.field_mapping_details : []);
            setCommunicationDetails(response.data.data.communication_details ? response.data.data.communication_details : []);
            setFAQDetails(response.data.data.faq_details ? response.data.data.faq_details : []);
            setTicketsDetails(response.data.data.tickets_details ? response.data.data.tickets_details : []);
            setCouponsDetails(response.data.data.coupon_details ? response.data.data.coupon_details : []);
            setAgeDetails(response.data.data.age_details ? response.data.data.age_details : []);
            setAgeCriteriaDetails(response.data.data.age_criteria_details ? response.data.data.age_criteria_details : []);
            setTermConditionsDetails(response.data.data.terms_conditions_details ? response.data.data.terms_conditions_details : []);
            setEventName(response.data.data.EventData[0] ? response.data.data.EventData[0].name : '');
            setFormTicketsDetails(response.data.data.form_tickets_details ? response.data.data.form_tickets_details : []);
            setCouponTicketsDetails(response.data.data.coupon_tickets_details ? response.data.data.coupon_tickets_details : []);
            setRaceCategoryChargesDetails(response.data.data.race_category_charges_details ? response.data.data.race_category_charges_details : []);
            //console.log(response);
            
            if(response.data.data.EventData[0]){ setEventTabsClass1(EventTabs === 1 ? 'active' : 'li-done'); setEventSvg1(green_tick_svg); }
            
            if(response.data.data.EventData[0].time_zone){ setEventTabsClass2(EventTabs === 2 ? 'active' : 'li-done'); setEventSvg2(green_tick_svg); }
            
           
            if(response.data.data.EventData[0].event_description){ setEventTabsClass3(EventTabs === 3 ? 'active' : 'li-done'); setEventSvg3(green_tick_svg); }
          
            if(response.data.data.event_setting_details && response.data.data.event_setting_details[0]){ setEventTabsClass4(EventTabs === 4 ? 'active' : 'li-done'); setEventSvg4(green_tick_svg); }
           
            if((response.data.data.tickets_details && response.data.data.tickets_details.length > 0) || (response.data.data.EventTickets && response.data.data.EventTickets.length > 0)){ setEventTabsClass5(EventTabs === 5 ? 'active' : 'li-done'); setEventSvg5(green_tick_svg); }
           
            if(response.data.data.event_form_question_details && response.data.data.event_form_question_details.length > 0){ setEventTabsClass6(EventTabs === 6 ? 'active' : 'li-done'); setEventSvg6(green_tick_svg); }
           
            if(response.data.data.age_criteria_details && response.data.data.age_criteria_details.length > 0){ setEventTabsClass7(EventTabs === 7 ? 'active' : 'li-done'); setEventSvg7(green_tick_svg); }
            
            if(response.data.data.coupon_details && response.data.data.coupon_details.length > 0){ setEventTabsClass8(EventTabs === 8 ? 'active' : 'li-done'); setEventSvg8(green_tick_svg); }

            // if(response.data.data.communication_details && response.data.data.communication_details.length > 0){ 
            if(response.data.data.comm_selected_flag && response.data.data.comm_selected_flag === 1){   
              setEventTabsClass9(EventTabs === 9 ? 'active' : 'li-done'); setEventSvg9(green_tick_svg); }

            if(response.data.data.faq_selected_flag && response.data.data.faq_selected_flag === 1){ setEventTabsClass10(EventTabs === 10 ? 'active' : 'li-done'); setEventSvg10(green_tick_svg); setEventTabsClass11(EventTabs === 11 ? 'active' : 'li-done'); setEventSvg11(green_tick_svg); }
            //setLoader(false);
          }
        })
        .catch(() => {
          console.log('error');
        });
    }
  }, [EventId, EventApiCall]);

  // console.log(EventCategoryAll);
  useEffect(() => {
    if (TicketDivShowFlag === true) {
      setDivClass('col-lg-12 col-md-6');
    } else { setDivClass('col-lg-8 col-md-6'); }
  }, [TicketDivShowFlag]);
  
  //-------------- To check user access rights
  const AccessRightFlag = localStorage.getItem("Access_Right_Flag");
  useEffect(() => {
    if(AccessRightFlag === '1'){
        const access = UserAccessRights({module_name: "Event", module_display: "no"});
        if(access === true){
          alert("You don't have any access to this module");
          navigate('/');
        }
    }
  }, []);

  return (
    <>
      <ToastContainer theme="colored" />

      <HeaderSecond />
      {loader ? <Loader /> : null}

      <main className="main__content_wrapper">
        {/* Start breadcrumb section */}
        <section className="breadcrumb__section breadcrumb__bg" style={{ backgroundImage: `url(${BreadcrumbBg})` }} >
          <div className="container">
            <div className="row row-cols-1">
              <div className="col">
                <div className="breadcrumb__content">
                  <h1 className="breadcrumb__content--title text-white mb-10">
                    {EventDispName}
                  </h1>
                  <ul className="breadcrumb__content--menu d-flex">
                    <li className="breadcrumb__content--menu__items">
                      <div className="text-white" onClick={(e) => handleBackToHome(e)}>
                        Home
                      </div>
                    </li>
                    <li className="breadcrumb__content--menu__items">
                      <span className="text-white">Design Your Event</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End breadcrumb section */}

        <section className="section--padding pt-5">
          <div className="container">

          <div className="event-timeline-ul">
          
            <div className={'event-timeline-li newtooltip-container ' + EventTabsClass1} onClick={() => {
                    setEventTabs(1);
                    setEventTabsClass1('active');
                    // setEventTabsClass2('');
                    // setEventTabsClass4('');
                    // setEventTabsClass3('');
                    // setEventTabsClass5('');
                    // setEventTabsClass6('');
                    // setEventTabsClass7('');
                    // setEventTabsClass8('');
                    // setEventTabsClass9('');
                    // setEventTabsClass10('');
                    // setEventTabsClass11('');
                    // setEventSvg2(common_svg);
                    // setEventSvg3(common_svg);
                    // setEventSvg4(common_svg);
                    // setEventSvg5(common_svg);
                    // setEventSvg6(common_svg);
                    // setEventSvg7(common_svg);
                    // setEventSvg8(common_svg);
                    // setEventSvg9(common_svg);
                    // setEventSvg10(common_svg);
                    // setEventSvg11(common_svg);
                    setEventResponceTabs(0);
                    setEventApiCall(true);
                    setTicketDivShowFlag(false);
                  }}>
              <div className="d-flex justify-content-center align-items-center"> 
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d={EventSvg1}/></svg>
                <span className="newtooltip">Event Essentials</span>
              </div>
            </div>
            
            <div className={'event-timeline-li newtooltip-container ' + EventTabsClass2} onClick={EventId !== null ? () => {
                      setEventTabs(2);
                      //setEventTabsClass1('li-done');
                      setEventTabsClass2('active');
                      // setEventTabsClass3('');
                      // setEventTabsClass4('');
                      // setEventTabsClass5('');
                      // setEventTabsClass6('');
                      // setEventTabsClass7('');
                      // setEventTabsClass8('');
                      // setEventTabsClass9('');
                      // setEventTabsClass10('');
                      // setEventTabsClass11('');
                      setEventSvg2(EventSvg1); 
                      // setEventSvg3(common_svg);
                      // setEventSvg4(common_svg);
                      // setEventSvg5(common_svg);
                      // setEventSvg6(common_svg);
                      // setEventSvg7(common_svg);
                      // setEventSvg8(common_svg);
                      // setEventSvg9(common_svg);
                      // setEventSvg10(common_svg);
                      // setEventSvg11(common_svg);
                      setEventResponceTabs(1);
                      setEventApiCall(true);
                      setTicketDivShowFlag(false);
                    } : null}>
              <div className="d-flex justify-content-center align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d={EventSvg2}/></svg>
                <span className="newtooltip">Event Scheduling</span>
              </div>
            </div>
            
            <div className={'event-timeline-li newtooltip-container ' + EventTabsClass3} onClick={EventId !== null ? () => {
                    setEventTabs(3);
                    setEventTabsClass3('active');
                    // setEventTabsClass1('li-done');
                    // setEventTabsClass2('li-done');
                    // setEventTabsClass4('');
                    // setEventTabsClass5('');
                    // setEventTabsClass6('');
                    // setEventTabsClass7('');
                    // setEventTabsClass8('');
                    // setEventTabsClass9('');
                    // setEventTabsClass10('');
                    // setEventTabsClass11('');
                   // setEventSvg2(EventSvg1);
                    setEventSvg3(EventSvg1);
                    // setEventSvg4(common_svg);
                    // setEventSvg5(common_svg);
                    // setEventSvg6(common_svg);
                    // setEventSvg7(common_svg);
                    // setEventSvg8(common_svg);
                    // setEventSvg9(common_svg);
                    // setEventSvg10(common_svg);
                    // setEventSvg11(common_svg);
                    setEventResponceTabs(2);
                    setEventApiCall(true);
                    setTicketDivShowFlag(false);
                  } : null
                  }>
              <div className="d-flex justify-content-center align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d={EventSvg3} /></svg>
                 <span className="newtooltip">Event Description</span>
              </div>
            </div>
            
            <div className={'event-timeline-li newtooltip-container ' + EventTabsClass4} onClick={EventId !== null ? () => {
                    setEventTabs(4);
                    // setEventTabsClass11('');
                    // setEventTabsClass5('');
                    // setEventTabsClass6('');
                    // setEventTabsClass7('');
                    // setEventTabsClass8('');
                    // setEventTabsClass9('');
                    // setEventTabsClass10('');
                    setEventTabsClass4('active');
                    // setEventTabsClass1('li-done');
                    // setEventTabsClass2('li-done');
                    // setEventTabsClass3('li-done');
                    // setEventSvg2(EventSvg1);
                    // setEventSvg3(EventSvg1);
                    setEventSvg4(EventSvg1);
                    // setEventSvg5(common_svg);
                    // setEventSvg6(common_svg);
                    // setEventSvg7(common_svg);
                    // setEventSvg8(common_svg);
                    // setEventSvg9(common_svg);
                    // setEventSvg10(common_svg);
                    // setEventSvg11(common_svg);
                    setEventResponceTabs(3);
                    setEventApiCall(true);
                    setTicketDivShowFlag(false);
                  } : null
                  }>
              <div className="d-flex justify-content-center align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d={EventSvg4} /></svg>
                 <span className="newtooltip">Event Settings</span>  {/* Settings */}
              </div>
            </div>
           
            <div className={'event-timeline-li newtooltip-container ' + EventTabsClass5} onClick={EventId !== null ? () => {
                    setEventTabs(5);
                    // setEventTabsClass11('');
                    // setEventTabsClass5('active');
                    // setEventTabsClass1('li-done');
                    // setEventTabsClass2('li-done');
                    // setEventTabsClass3('li-done');
                    // setEventTabsClass4('li-done');
                    // setEventTabsClass6('');
                    // setEventTabsClass7('');
                    // setEventTabsClass8('');
                    // setEventTabsClass9('');
                    // setEventTabsClass10('');
                    // setEventSvg2(EventSvg1);
                    // setEventSvg3(EventSvg1);
                    // setEventSvg4(EventSvg1);
                    setEventSvg5(EventSvg1);
                    // setEventSvg6(common_svg);
                    // setEventSvg7(common_svg);
                    // setEventSvg8(common_svg);
                    // setEventSvg9(common_svg);
                    // setEventSvg10(common_svg);
                    // setEventSvg11(common_svg);
                    setEventResponceTabs(4);
                    setEventApiCall(true);
                    setTicketDivShowFlag(false);
                    //setNewFlag(localStorage.setItem("Tid", 1));
                  } : null
                  }>
              <div className="d-flex justify-content-center align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d={EventSvg5} /></svg>
                 <span className="newtooltip">Race Categories</span> {/* Tickets */}
              </div>
            </div>
            
            <div className={'event-timeline-li newtooltip-container ' + EventTabsClass6} onClick={EventId !== null ? () => {
                    setEventTabs(6);
                    // setEventTabsClass11('');
                    // setEventTabsClass10('');
                    // setEventTabsClass9('');
                    // setEventTabsClass8('');
                    // setEventTabsClass7('');
                    setEventTabsClass6('active');
                    // setEventTabsClass5('li-done');
                    // setEventTabsClass1('li-done');
                    // setEventTabsClass2('li-done');
                    // setEventTabsClass3('li-done');
                    // setEventTabsClass4('li-done');
                    setEventSvg6(EventSvg1);
                    // setEventSvg2(EventSvg1);
                    // setEventSvg3(EventSvg1);
                    // setEventSvg4(EventSvg1);
                    // setEventSvg5(EventSvg1);
                    // setEventSvg7(common_svg);
                    // setEventSvg8(common_svg);
                    // setEventSvg9(common_svg);
                    // setEventSvg10(common_svg);
                    // setEventSvg11(common_svg);
                    setEventResponceTabs(5);
                    setEventApiCall(true);
                    setTicketDivShowFlag(false);
                  } : null
                  }>
              <div className="d-flex justify-content-center align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d={EventSvg6} /></svg>
                 <span className="newtooltip">Form Questions</span>
              </div>
            </div>
            
            <div className={'event-timeline-li newtooltip-container ' + EventTabsClass7} onClick={EventId !== null ? () => {
                    setEventTabs(7);
                    // setEventTabsClass11('');
                    // setEventTabsClass10('');
                    // setEventTabsClass9('');
                    // setEventTabsClass8('');
                    setEventTabsClass7('active');
                    // setEventTabsClass6('li-done');
                    // setEventTabsClass5('li-done');
                    // setEventTabsClass1('li-done');
                    // setEventTabsClass2('li-done');
                    // setEventTabsClass3('li-done');
                    // setEventTabsClass4('li-done');
                    setEventSvg7(EventSvg1);
                    // setEventSvg6(EventSvg1);
                    // setEventSvg2(EventSvg1);
                    // setEventSvg3(EventSvg1);
                    // setEventSvg4(EventSvg1);
                    // setEventSvg5(EventSvg1);
                    // setEventSvg8(common_svg);
                    // setEventSvg9(common_svg);
                    // setEventSvg10(common_svg);
                    // setEventSvg11(common_svg);
                    setEventResponceTabs(6);
                    setEventApiCall(true);
                    setTicketDivShowFlag(false);
                  } : null
                  }>
              <div className="d-flex justify-content-center align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d={EventSvg7} /></svg>
                 <span className="newtooltip">Age Category</span>
              </div>
            </div>
            
            <div className={'event-timeline-li newtooltip-container ' + EventTabsClass8} onClick={EventId !== null ? () => {
                    setEventTabs(8);
                    // setEventTabsClass11('');
                    // setEventTabsClass10('');
                    // setEventTabsClass9('');
                    setEventTabsClass8('active');
                    // setEventTabsClass7('li-done');
                    // setEventTabsClass6('li-done');
                    // setEventTabsClass5('li-done');
                    // setEventTabsClass1('li-done');
                    // setEventTabsClass2('li-done');
                    // setEventTabsClass3('li-done');
                    // setEventTabsClass4('li-done');
                    // setEventSvg7(EventSvg1);
                    // setEventSvg6(EventSvg1);
                    // setEventSvg2(EventSvg1);
                    // setEventSvg3(EventSvg1);
                    // setEventSvg4(EventSvg1);
                    // setEventSvg5(EventSvg1);
                    setEventSvg8(EventSvg1);
                    // setEventSvg9(common_svg);
                    // setEventSvg10(common_svg);
                    // setEventSvg11(common_svg);
                    setEventResponceTabs(7);
                    setEventApiCall(true);
                    setTicketDivShowFlag(false);
                  } : null
                  }>
              <div className="d-flex justify-content-center align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d={EventSvg8} /></svg>
                 <span className="newtooltip">Discount Coupons</span> 
              </div>
            </div>
            
            <div className={'event-timeline-li newtooltip-container ' + EventTabsClass9} onClick={EventId !== null ? () => {
                    setEventTabs(9);
                    // setEventTabsClass11('');
                    // setEventTabsClass10('');
                    setEventTabsClass9('active');
                    // setEventTabsClass8('li-done');
                    // setEventTabsClass7('li-done');
                    // setEventTabsClass6('li-done');
                    // setEventTabsClass5('li-done');
                    // setEventTabsClass1('li-done');
                    // setEventTabsClass2('li-done');
                    // setEventTabsClass3('li-done');
                    // setEventTabsClass4('li-done');
                    // setEventSvg7(EventSvg1);
                    // setEventSvg6(EventSvg1);
                    // setEventSvg2(EventSvg1);
                    // setEventSvg3(EventSvg1);
                    // setEventSvg4(EventSvg1);
                    // setEventSvg5(EventSvg1);
                    // setEventSvg8(EventSvg1);
                    setEventSvg9(EventSvg1);
                    // setEventSvg10(common_svg);
                    // setEventSvg11(common_svg);
                    setEventResponceTabs(8);
                    setEventApiCall(true);
                    setTicketDivShowFlag(false);
                  } : null
                  }>
              <div className="d-flex justify-content-center align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d={EventSvg9} /></svg>
                 <span className="newtooltip">Communications</span> 
              </div>
            </div>
            
            <div className={'event-timeline-li newtooltip-container ' + EventTabsClass10} onClick={EventId !== null ? () => {
                    setEventTabs(10);
                   // setEventTabsClass11('');
                    setEventTabsClass10('active');
                    // setEventTabsClass9('li-done');
                    // setEventTabsClass8('li-done');
                    // setEventTabsClass7('li-done');
                    // setEventTabsClass6('li-done');
                    // setEventTabsClass5('li-done');
                    // setEventTabsClass1('li-done');
                    // setEventTabsClass2('li-done');
                    // setEventTabsClass3('li-done');
                    // setEventTabsClass4('li-done');
                    // setEventSvg7(EventSvg1);
                    // setEventSvg6(EventSvg1);
                    // setEventSvg2(EventSvg1);
                    // setEventSvg3(EventSvg1);
                    // setEventSvg4(EventSvg1);
                    // setEventSvg5(EventSvg1);
                    // setEventSvg8(EventSvg1);
                    // setEventSvg9(EventSvg1);
                    setEventResponceTabs(9);
                    setEventSvg10(EventSvg1);
                   // setEventSvg11(common_svg);
                    setEventApiCall(true);
                    setTicketDivShowFlag(false);
                  } : null
                  }>
              <div className="d-flex justify-content-center align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d={EventSvg10} /></svg>
                 <span className="newtooltip">FAQ's</span>
              </div>
            </div>
            
            <div className={'event-timeline-li newtooltip-container ' + EventTabsClass11} onClick={EventId !== null ? () => {
                    setEventTabs(11);
                    setEventTabsClass11('active');
                    // setEventTabsClass10('li-done');
                    // setEventTabsClass9('li-done');
                    // setEventTabsClass8('li-done');
                    // setEventTabsClass7('li-done');
                    // setEventTabsClass6('li-done');
                    // setEventTabsClass5('li-done');
                    // setEventTabsClass1('li-done');
                    // setEventTabsClass2('li-done');
                    // setEventTabsClass3('li-done');
                    // setEventTabsClass4('li-done');
                    // setEventSvg7(EventSvg1);
                    // setEventSvg6(EventSvg1);
                    // setEventSvg2(EventSvg1);
                    // setEventSvg3(EventSvg1);
                    // setEventSvg4(EventSvg1);
                    // setEventSvg5(EventSvg1);
                    // setEventSvg8(EventSvg1);
                    // setEventSvg9(EventSvg1);
                    // setEventSvg10(EventSvg1);
                    setEventResponceTabs(10);
                    setEventSvg11(EventSvg1);
                    setEventApiCall(true);
                    setTicketDivShowFlag(false);
                  } : null
                  }>
              <div className="d-flex justify-content-center align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d={EventSvg11}/></svg>
                 <span className="newtooltip">Integrations</span>  {/* Age Criteria */}
              </div>
            </div>
        
          </div>


            {/* {console.log(EventResponceTabs,EventTabs,EventId)} */}
            <div className="row mt-5">

              <div className={DivClass}>
                {
                  EventTabs === 1 ?
                    <BasicInformation setEventResponceTabs={setEventResponceTabs} setEventBasicInfoMsg={setEventBasicInfoMsg} EventData={EventDetailsAll} EventCategoryData={EventCategoryAll} EventTypeData={EventTypeAll} />

                    : EventTabs === 2 && EventId !== 0 && EventId ?

                      <EventDuration setEventResponceTabs={setEventResponceTabs} setEventDurationMsg={setEventDurationMsg} EventData={EventDetailsAll} setLoader={setLoader} />

                      : EventTabs === 3 && EventId !== 0 && EventId ?

                        <EventDescription setEventResponceTabs={setEventResponceTabs} setEventDescriptionMsg={setEventDescriptionMsg} EventData={EventDetailsAll} EventImgData={EventImagesAll} setEventApiCall={setEventApiCall} />

                        : EventTabs === 4 && EventId !== 0 && EventId ?

                          <Settings setEventResponceTabs={setEventResponceTabs} EventData={EventDetailsAll} EventSettingDetails={EventSettingDetails} YtcrFeePercent={YtcrFeePercent} />

                          : EventTabs === 5 && EventId !== 0 && EventId ?

                            <Tickets setEventResponceTabs={setEventResponceTabs} EventData={EventDetailsAll} EventTicketsAll={EventTicketsAll} setEventApiCall={setEventApiCall} YtcrFeePercent={YtcrFeePercent} PlatformFeePercent={PlatformFeePercent} PaymentGatewayFeePercent={PaymentGatewayFeePercent} setTicketDivShowFlag={setTicketDivShowFlag} TicketDivShowFlag={TicketDivShowFlag} setNewFlag={setNewFlag} NewFlag={NewFlag} EventTypeData={EventTypeAll} PaymentGatewayGstPercent={PaymentGatewayGstPercent} AgeDetails={AgeDetails} RaceCategoryChargesDetails={RaceCategoryChargesDetails} />

                            : EventTabs === 6 && EventId !== 0 && EventId ?

                              <FormQuestions setEventResponceTabs={setEventResponceTabs} EventData={EventDetailsAll} FieldMappingDetails={FieldMappingDetails} setEventApiCall={setEventApiCall} TicketsDetails={FormTicketsDetails} />

                              : EventTabs === 7 && EventId !== 0 && EventId ?

                                 // AgeCriteria
                                  <AgeCriteria setEventResponceTabs={setEventResponceTabs} EventData={EventDetailsAll} setTicketDivShowFlag={setTicketDivShowFlag} TicketDivShowFlag={TicketDivShowFlag} FAQDetails={FAQDetails} setEventApiCall={setEventApiCall} TicketsDetails={TicketsDetails} AgeCriteriaDetails={AgeCriteriaDetails} AgeDetails={AgeDetails} />

                                : EventTabs === 8 && EventId !== 0 && EventId ?
                                    <Coupons setEventResponceTabs={setEventResponceTabs} EventData={EventDetailsAll} setTicketDivShowFlag={setTicketDivShowFlag} TicketDivShowFlag={TicketDivShowFlag} setEventApiCall={setEventApiCall} TicketsDetails={CouponTicketsDetails} CouponsDetails={CouponsDetails} />
                                 

                                  : EventTabs === 9 && EventId !== 0 && EventId ?

                                    <Communication setEventResponceTabs={setEventResponceTabs} EventData={EventDetailsAll} setTicketDivShowFlag={setTicketDivShowFlag} TicketDivShowFlag={TicketDivShowFlag} CommunicationDetails={CommunicationDetails} setEventApiCall={setEventApiCall} TermConditionsDetails={TermConditionsDetails} />

                                  :  EventTabs === 10 && EventId !== 0 && EventId ?
                                    
                                    <EventFAQ setEventResponceTabs={setEventResponceTabs} EventData={EventDetailsAll} setTicketDivShowFlag={setTicketDivShowFlag} TicketDivShowFlag={TicketDivShowFlag} FAQDetails={FAQDetails} setEventApiCall={setEventApiCall} />
                                   
                                  :
                                    <Integration setEventResponceTabs={setEventResponceTabs} EventData={EventDetailsAll} EventName={EventName} EventTcsPopupFlag={EventTcsPopupFlag}/>
                }

              </div>

              {TicketDivShowFlag === true ?
                ''
                :
                <div className="col-lg-4 col-md-6 d-md-2-none">

                  <aside className="checkout__sidebar sidebar border-radius-10">
                    <h2 className="section__header--title text-center h3 mb-5">
                      Event Preview
                    </h2>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="card" style={{ borderRadius: 15 }}>

                          {/* EVENT CARD */}

                          <div key={EventCardPreview.id} className="product__items ">
                            <div className="product__items--thumbnail" >
                              <div className="product__items--link">
                                {EventDetailsAll ?
                                  <img
                                    className="product__items--img product__primary--img"
                                    src={EventDetailsAll.banner_image !== '' ? EventDetailsAll.banner_image : event_card_img}
                                    alt="Event View"
                                    style={{ height: '230px', objectFit: 'fill' }}
                                  />
                                  :
                                  <img
                                    className="product__items--img product__primary--img"
                                    src={event_card_img}
                                    alt="Event View"
                                    style={{ height: '230px', objectFit: 'fill' }}
                                  />
                                }
                              </div>
                              {(EventCardPreview.promoted === 1) ?
                                <div className="product__badge style1 special-badge gap-1">
                                  Early Bird
                                </div>
                                : null}
                            </div>

                            <div className="product__items--content cursor-pointer" style={{ height: "200px", overflow: "hidden" }}>

                              <div className="row">
                                <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex flex-column align-items-center justify-content-center" style={{ borderRight: "1px solid #f52222" }}>
                                  <h4 className="h4">{EventCardPreview.pre_start_event_month ? EventCardPreview.pre_start_event_month : null}</h4>
                                  <h4 className="h4">{EventCardPreview.pre_start_event_date ? EventCardPreview.pre_start_event_date : null}</h4>
                                </div>
                                <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10" >

                                  <h4 className="h4 d-flex" style={{ height: "55px", overflow: "hidden" }}>  {(EventCardPreview.event_name) ? EventCardPreview.event_name : "Event Name"}</h4>
                                </div>
                              </div>

                              <div className='my-3 d-flex gap-3'>

                                <small className="m-0 card-event-city">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height={17}
                                    viewBox="0 -960 960 960"
                                    width={17}
                                  >
                                    <path d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z" />
                                  </svg> {EventCardPreview.city ? EventCardPreview.city : "City Name"}</small>
                              </div>

                              <div className="row d-flex align-items-center card-rgn-bt-dot">
                                <div className="col-sm-12 mt-4 mb-0">
                                  <small className='mb-2 card-rgn-txt'>Register By
                                : {" "}  <span className='rgn-date'>{EventCardPreview.registration_end_date ? EventCardPreview.registration_end_date : null}</span></small>
                                </div>

                                <div className="col-sm-6 my-3">
                                  {((EventCardPreview.no_of_tickets > 1) && (EventCardPreview.min_price !== EventCardPreview.max_price)) ?
                                    <p className="current__price m-0"> ₹{EventCardPreview.min_price} - ₹{EventCardPreview.max_price}</p>
                                    :
                                    ((EventCardPreview.no_of_tickets !== 0) && (EventCardPreview.no_of_tickets === 1)) ? <p className="current__price m-0"> ₹{EventCardPreview.max_price}</p> : null
                                  }
                                </div>

                                <div className="col-sm-6 my-3 text-right">
                                  <p className="blog__content--btn primary__btn"
                                  //</div>onClick={(e) => { props.setEventViewFlag(true); props.setEventDetailsId(EventCardPreview.id) }}
                                  >Register</p>
                                </div>
                              </div>

                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </aside>
                </div>
              }
            </div>

          </div>
        </section>

      </main >

    </>
  )
}

export default CreateEvent;

