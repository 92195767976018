import React, { useEffect, useState } from "react";
import axios from "../axios";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import LoaderImg from '../img/logo/running.gif';
import Login from "../logo/loginsuccess.gif";
import redirectImg from '../img/other/redirect.gif';
import { ToastContainer } from 'react-toastify';
import { toast } from "react-toastify";

const PaymentConfirmPopup = (props) => {

  const navigate = useNavigate();
  // const EventId = localStorage.getItem("EventId");
  const [UserDetails, setUserDetails] = useState([]);
  const [TicketBookingFlag, setTicketBookingFlag] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [ButtonDisplayFlag, setButtonDisplayFlag] = useState('');
  
  // console.log(props.BookTicktesArray);
  let NewEventId = 0;
  let FinalAmount = 0;

  if(props.BookTicktesArray){
    NewEventId  = props.BookTicktesArray.event_id; 
    FinalAmount = props.BookTicktesArray.TotalPrice;
  }
  // console.log(props.NewFilesArray);

  useEffect(() => {
    setLoader(true);
    const formData = new FormData();

    for (let i = 0; i < props.NewFilesArray.length; i++) {
      formData.append("fils_array[]", props.NewFilesArray[i]);
    }

    formData.append('event_id', props.EventId ? props.EventId : NewEventId);
    formData.append('amount', props.FinalAmount ? props.FinalAmount : FinalAmount);
    formData.append('ticket_type', 'paid');
    formData.append('booking_tickets_array', JSON.stringify(props.BookTicktesArray));
    // formData.append('files_array', props.NewFilesArray);

    axios.post("bookingPaymentProcess", formData)
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          const UserDetails = response.data.data;
          // console.log(UserDetails);
          setUserDetails(UserDetails);
          localStorage.setItem("booking_pay_id", response.data.data.booking_pay_id);
          setButtonDisplayFlag('is-visible');
          setLoader(false);
        }else{
          setButtonDisplayFlag('');
          toast.error("Something went wrong");
          const timer = setTimeout(() => {
              navigate('/register_now/'+localStorage.getItem('RegEventId'));
            }, 6000); // Adjust the delay as needed (in milliseconds)
          return () => clearTimeout(timer);
          setLoader(false);
        }
       
      })
      .catch(() => {
        console.log('error');
        setLoader(false);
        //props.setOpenTree(false);
      });

  }, [props.EventId]);

  //----------- book ticket entry
  const bookingPayId = localStorage.getItem('booking_pay_id');

  let success_url = '';
  let failure_url = '';

  const url_flag = 2;  // prime - 1 / live - 2 / local - 3 

  if (url_flag === 1) {
    success_url = 'https://swtprime.com/Races2.0_Backend/Transcation/Payment_process.php';
    failure_url = 'https://swtprime.com/Races2.0_Backend/Transcation/Payment_process.php';
  } else if (url_flag === 2) {
    success_url = 'https://racesregistrations.com/races/Transcation/Payment_process.php';
    failure_url = 'https://racesregistrations.com/races/Transcation/Payment_process.php';
  } else {
    success_url = 'http://localhost/ytcr_backend/Transcation/Payment_process.php';
    failure_url = 'http://localhost/ytcr_backend/Transcation/Payment_process.php';
  }

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  useEffect(() => {
    const handleKeyDown = (event) => {
      event.preventDefault();
      //console.log("Key press is disabled.");
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      {Loader ? <div><div id='loader' style={{ backgroundImage: `url(${LoaderImg})` }}></div></div> : null}

      <div className={"modal " + ButtonDisplayFlag} data-animation="slideInUp">
        <div className="modal-dialog quickview__main--wrapper">
          <div className="quickview__inner">
            <div className="row">
              <div className="login__section">
                <div className="login__section--inner">
                
                      <div className="p-5">
                        <div className="account__login--header mb-25 text-center">

                          <img src={redirectImg} alt="" style={{ height: 250 }} />
                          <p className='h3 mb-3'>Redirect to payment</p>
                          <p className="account__login--header__desc">You are being redirected to payment gateway site</p>
                        </div>
                        <div className="text-center">
                          <form action='https://secure.payu.in/_payment' method='post'>
                            <input type="hidden" name="key" value={UserDetails.merchant_key} />
                            <input type="hidden" name="txnid" value={UserDetails.txnid} />
                            <input type="hidden" name="productinfo" value={UserDetails.productinfo} />
                            <input type="hidden" name="amount" value={UserDetails.amount} />
                            <input type="hidden" name="email" value={UserDetails.email} />
                            <input type="hidden" name="firstname" value={UserDetails.firstname} />
                            <input type="hidden" name="surl" value={success_url} />
                            <input type="hidden" name="furl" value={failure_url} />
                            <input type="hidden" name="hash" value={UserDetails.hash} />

                            <input type="submit" name="command" value="Pay Now" className="account__login--btn success__btn mt-2" onClick={(e) => { setTicketBookingFlag(true); }} />
                          </form>
                          
                        </div>
                      </div>
                     
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default PaymentConfirmPopup;
