import React, { useState, useEffect } from 'react';
import '../App.css';
import LoaderImg from '../img/logo/running.gif';
import FormQuestionPopup from "../Create_event_details/FormQuestionPopup";
import SubQuestionTreePopup from "../Create_event_details/SubQuestionTreePopup";
import AddCustomFormQuestions from "../Create_event_details/AddCustomFormQuestions";
import axios from '../axios';
import { toast } from 'react-toastify';

const AddGeneralQuestionsForm = (props) => {
  
  const [GeneralFormQuestionAll, setGeneralFormQuestionAll] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [questionName, setquestionName] = useState('');
  const [formName, setformName] = useState('');
  const [questionId, setquestionId] = useState('');
  const [questionType, setquestionType] = useState('');
  const [questionOptionArray, setquestionOptionArray] = useState([]);
  const [AddCustomQuestions, setAddCustomQuestions] = useState(false);
  const [isSubquestion, setisSubquestion] = useState(0);
  const [answers, setAnswers] = useState(['']);
  const [openTree, setOpenTree] = React.useState(false);
  const [keysToCheck, setkeysToCheck] = useState([]);
  const [TicketsDetailsData, setTicketsDetailsData] = useState([]);
  const [fieldMapping, setfieldMapping] = useState('');
  const [MainQuestionHint, setMainQuestionHint] = useState('');
  const [HintType, setHintType] = useState(1);
  // console.log(fieldMapping);

  // console.log(props.TicketsDetails);

  useEffect(() => {
    const payload = {
       event_id: localStorage.getItem("EventId") ? localStorage.getItem("EventId") : 0,
       user_id: localStorage.getItem("ID") ? localStorage.getItem("ID") : 0,
    };
    axios.post("GeneralFormQuestions",payload)
      .then((response) => {
      //  console.log(response.data.data);
        if (response.status === 200) {
          if(response.data.data.length !== 0){
            setGeneralFormQuestionAll(response.data.data.form_question);
          }else{
            setGeneralFormQuestionAll([]);
          }
          // setTypeApiCall(false)
        }
      })
      .catch((error) => {
        console.log(error);
      })

}, [AddCustomQuestions])
//console.log(GeneralFormQuestionAll.length === 0 ? 'ss' : 'no');

useEffect(() => {
  const handleKeyPress = (event) => {
      if (event.key === "Escape") {
          // Close the popup when the escape key is pressed
          setOpen(false); setOpenTree(false);
      }
  };
  // Add event listener when the component mounts
  document.addEventListener("keydown", handleKeyPress);
  // Remove event listener when the component unmounts
  return () => {
      document.removeEventListener("keydown", handleKeyPress);
  };
}, []);

useEffect(() => {
  window.scrollTo(0, 0);
  const payload = {
    form_name: '',
    form_edit_id: 0,
    form_action_flag: 'form_details',
    form_flag: 'general_form'
  };
  axios.post("FormCommonDetails", payload)
    .then((response) => {
      // console.log(response.data.data.form_array);
      if (response.status === 200) {
        setkeysToCheck(response.data.data.form_array);
      }
    })
    .catch((error) => {
      console.log(error);
    })

}, [])

const add_svg = "M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z";

// const keysToCheck = ["1", "2", "3", "4", "5", "6", "7", "8"];
//const keysToCheck = ["Personal Information","Address Details", "Medical Information", "Documentation", "Emergency Contact", "Additional Information", "Professional Information", "Social Media Links","Other Details"];

  return (
    <>
      {open ? <FormQuestionPopup setOpen={setOpen} questionName={questionName} questionId={questionId} setEventResponceTabs={props.setEventResponceTabs} setAddGeneralQuestions={props.setAddGeneralQuestions} questionType={questionType} questionOptionArray={questionOptionArray} isSubquestion={isSubquestion} FieldMappingDetails={props.FieldMappingDetails} formName={formName} TicketsDetails={props.TicketsDetails} fieldMappingValue={fieldMapping} MainQuestionHint={MainQuestionHint} HintType={HintType} /> : null}
      
      {openTree ? <SubQuestionTreePopup setOpenTree={setOpenTree} questionId={questionId} setAddGeneralQuestions={props.setAddGeneralQuestions} openTree={openTree} FieldMappingDetails={props.FieldMappingDetails} formName={formName} TicketsDetails={TicketsDetailsData} /> : null}

      {Loader ? <div><div id='loader' style={{ backgroundImage: `url(${LoaderImg})` }}></div></div> : null}
      
      {AddCustomQuestions ?
          <AddCustomFormQuestions setAddCustomQuestions={setAddCustomQuestions} />
      :
      <div className="main checkout__mian">
        {/* <form onSubmit={(e) => handleSubmit(e)}> */}
         
        <div className="checkout__content--step section__shipping--address p-0 ">
          {/* <div className="section__header mb-4 d-flex align-items-center justify-content-between">
              <h2 className="section__header--title h3">General Form Questions</h2>
              <div className="continue__shipping--btn secondary__btn border-radius-5" onClick={() => { setAddCustomQuestions(true); }}>
                  <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg> Add Custom Questions
              </div>
          </div> */}

          <div className="section__header mb-4 d-flex align-items-center justify-content-between">
              <h2 className="section__header--title h3">General Form Questions</h2>
              <div className="continue__shipping--btn secondary__btn border-radius-5"  onClick={() => { setAddCustomQuestions(true); }} style={{cursor: 'pointer'}}>
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/></svg> Add custom question
              </div>
          </div>

          {/* <div className="checkout__content--step section__shipping--address "> */}
          <div className="row">
            
          {GeneralFormQuestionAll && GeneralFormQuestionAll.length !== 0 ? Object.entries(GeneralFormQuestionAll).map(([key, formArry]) => (
            <div className="row" key={key}>
              {/* Add <hr /> after each iteration of a specific key */}
              {keysToCheck.includes(key) ? <div><hr className='my-4'/><h4 className="mb-3 ">{key === 'Do Not Have Form' ? 'Custom Form' : key}:</h4></div> : null}

              {Object.values(formArry).map((item, index) => 
                item.event_questions_flag === 1 && item.is_subquestion === 0 ?
               (           
                   
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 mb-5 add-que" key={index} >            
                  <div className="card w-100" style={{minHeight: '93px'}}>
                    <div className="text-left mx-3 w-100">
                      <p className="mb-1">{item.question_label}</p> 
                      {/* <small className="small-txt">( {item.question_form_type} input type question )</small> */}
                    </div>

                    <div className="float-btn">
                      
                      {item.event_questions_flag === 1 && item.question_form_option.length !== 0 && item.sub_questions_added_flag === 1 ?
                          <div className="tooltip view-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => { setOpenTree(true); setquestionId(item.id); setTicketsDetailsData(props.TicketsDetails) }}><path d={add_svg} /></svg>
                            <span className="tooltiptext border-radius-10 p-4">
                                <small className="text-white">Sub-Question Details</small>
                            </span>
                          </div>
                        : '' }

                        <div className="tooltip added-btn">
                          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                          <span className="tooltiptext border-radius-10 p-4">
                                <small className="text-white">Question Already Added</small>
                          </span>
                        </div>
                    </div>

                  </div>
                </div>
             
            ) : item.event_questions_flag === 1 && item.sub_question_json_tick_count === 1 ? // && item.is_subquestion === 1
            (
               
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 mb-5 add-que added" key={index} >            
                <div className="card w-100" style={{minHeight: '93px'}}>
                  <div className="text-left mx-3 w-100">
                    <p className="mb-1">{item.question_label}</p> 
                    {/* <small className="small-txt">( {item.question_form_type} input type question )</small> */}
                  </div>
                 
                  <div className="float-btn">
                     
                      {item.event_questions_flag === 1 && item.question_form_option ?
                        <div className="tooltip view-btn">
                          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"  onClick={() => { setOpenTree(true); setquestionId(item.id); }}><path d={add_svg} /></svg>
                          <span className="tooltiptext border-radius-10 p-4">
                              <small className="text-white">Sub-Question Details</small>
                          </span>
                        </div>
                      : '' }

                      <div className="tooltip added-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                        <span className="tooltiptext border-radius-10 p-4">
                              <small className="text-white">Question Already Added</small>
                        </span>
                      </div>
                  </div>


                </div>
              </div>
           
            ) : (
              
            <div className=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 mb-5 add-que" key={index} onClick={() => { setquestionName(item.question_label); setquestionId(item.id); setquestionType(item.question_form_type); setquestionOptionArray(item.question_form_option); setisSubquestion(item.is_subquestion); setformName(item.form_id); setfieldMapping(item.user_field_mapping); setMainQuestionHint(item.question_hint); setHintType(item.hint_type) }}>
              <div className="card w-100" style={{minHeight: '93px'}}>
                <div className="text-left mx-3 w-100">
                  <p className="mb-1">{item.question_label}</p>
                  {/* <small className="small-txt">( {item.question_form_type} input type question )</small> */}
                </div>
              
                <div className="float-btn">

                  {item.event_questions_flag === 1 ?
                    <div className="view-btn">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d={add_svg} onClick={() => { setOpenTree(true); setquestionId(item.id); }} /></svg>
                    </div>
                  : '' }

                  <div className="tooltip add-btn">

                    {/* { (item.question_label === 'Country' && item.country_flag !== '') || (item.question_label === 'State' && item.state_flag !== '') || (item.question_label === 'City' && item.city_flag !== '') ?
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => { setOpen(true); }}><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg>
                    : item.question_label !== 'Country' && item.question_label !== 'State' && item.question_label !== 'City' ?
                     <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => { setOpen(true); }}><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg>
                    : ''
                    } */}

                     { (item.question_label === 'State' && item.state_flag === 1) ?
                        <div>
                              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => { setOpen(true); }}><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg>
                              <span className="tooltiptext border-radius-10 p-4">
                                <small className="text-white">Add this question to form</small>
                              </span>
                        </div>
                        
                      : (item.question_label === 'City' && item.city_flag === 1) ?
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => { setOpen(true); }}><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg>
                            <span className="tooltiptext border-radius-10 p-4">
                              <small className="text-white">Add this question to form</small>
                            </span>
                        </div>
                      : (item.question_label !== 'State' && item.question_label !== 'City') ?
                        <div>
                          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => { setOpen(true); }}><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg>
                          <span className="tooltiptext border-radius-10 p-4">
                            <small className="text-white">Add this question to form</small>
                          </span>
                        </div>
                      : 
                        <div>
                          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="m40-120 440-760 440 760H40Zm138-80h604L480-720 178-200Zm302-40q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Zm40-100Z"/></svg>
                          <span className="tooltiptext border-radius-10 p-4">
                            <small className="text-white">{item.show_msg}</small>
                          </span>
                        </div>

                    }

                    
                  </div>
                </div>

              </div>
            </div>
            
          )
               
              )}
              
            </div>
          ))
          : 
          (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-20 no-ticket">
            <div className="row coupon">
              <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 left">

              </div>
              <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-6 col-xxl-8 center">
                <div>
                  <p className="section__header--title h4">No Questions Added</p>
                  <hr />
                  <p className="h4">Please click on add " custom question" button to add new question</p>
                  <br />
                  <div className="continue__shipping--btn secondary__btn border-radius-5" onClick={() => { setAddCustomQuestions(true); }} style={{ cursor: 'pointer' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg>
                    New Custom Question
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 right">
                <div>

                </div>
              </div>
            </div>
          </div>
          )
        }

          </div>
         
          <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
            <div
              className="continue__shipping--btn secondary__btn border-radius-5"
              onClick={(e) => { props.setAddGeneralQuestions(false); }}
              style={{cursor: 'pointer'}}
            >
              Cancel
            </div>

          </div>
        {/* </form > */}
      </div >
      </div >
      }
    </>
  )
}

export default AddGeneralQuestionsForm;

