// import React from 'react';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "./axios";
import './App.css';
import EventView from '../src/img/web_image.png';
import ytcrlogo from '../src/img/logo/ytcr-logo.png';

const MaintenanceMode = () => {
    // console.log("rjfjdk");
    const navigate = useNavigate();
    const [Showmaintancemode, setShowmaintancemode] = useState(null);
    
    useEffect(() => {
        // if (token) {
        axios.get("get_maintancemode")
          .then((response) => {
            const mode = response.data.data.maintance_mode[0].maintenance_mode;
            setShowmaintancemode(mode);
  
            if (mode !== 0) {
              navigate("/maintenance_mode");
            }else{
              navigate("/:country_code/:country");
            }
          })
          .catch((error) => {
            console.error("Error fetching maintenance mode:", error);
          });
      // }
    }, [navigate]);

  return (
   
    <div style={{background: 'linear-gradient(145deg, rgb(251 208 201 / 20%) 0%, rgb(255 209 253 / 20%) 100%)'}}>
      <div className="row w-100 px-4" >
        <div className="col-sm-12 d-flex align-items-center justify-content-center flex-column" style={{height:"100vh"}}>
          
          <img className="maintenance-ytcr-logo my-2" src={ytcrlogo} alt="ytcr logo"/>
          
          <img className="maintenance-img my-5" src={EventView} alt="maintenance img" />
          
          <h1 className="maintenance-title text-center">The site is currently down for maintenance</h1>
          
          <h3 className="maintenance-subtitle text-center my-3">We apologize for any inconvenviences caused.</h3>
        
        </div>
        
      </div>
    </div>
  );
};

export default MaintenanceMode;