import React, { useEffect, useState, useRef } from "react";
import axios from '../axios'

const ShareEvent = (props) => {
   const DivRef = useRef(null);
   const EventIdShareEvent = props.EventIdShareEvent;
   const [Copy, setCopy] = useState('Copy Link');
   const [NewSharedUrl, setNewSharedUrl] = useState('');

   const handleClose = (e) => {
      if (DivRef.current && !DivRef.current.contains(e.target)) {
         props.setShareEventFlag(false);
      }
   }

   // const handleShareEvent = (params) => {
   //    var eventName = props.EventName;
   //    var a = window.location.origin + process.env.PUBLIC_URL;
   //    var url;
   //    const formattedEventName = eventName.replace(/\s/g, '_');
   //    // console.log(formattedEventName);
   //    if (params === 'whatsapp') url = 'https://web.whatsapp.com/send?text=' + a + '/e/' + formattedEventName;
   //    if (params === 'facebook') url = 'https://www.facebook.com/login.php?text=' + a + '/e/' + formattedEventName;
   //    if (params === 'twitter') url = 'https://twitter.com/intent/tweet?text=' + a + '/e/' + formattedEventName;
   //    if (params === 'linkedin') url = 'https://www.linkedin.com/uas/login?text=' + a + '/e/' + formattedEventName;
   //    window.open(url, '_blank');
   // };

   // const handleCopyEvent = () => {
   //    var eventName = props.EventName;
   //    setCopy('Copied');
   //    var copyText = window.location.origin + process.env.PUBLIC_URL;
   //    // console.log(copyText);
   //    const formattedEventName = eventName.replace(/\s/g, '_');
   //    copyText = copyText + "/e/" + formattedEventName;
   //    navigator.clipboard.writeText(copyText);
   // }
   // console.log(EventIdShareEvent);
 
   //---------------- new create for share event
   useEffect(() => {
      axios.post('share_event_link', {'event_id' : EventIdShareEvent})
      .then((response) => {
         // console.log(response.data.data);
         if (response.status === 200) {
            if(response.data.data.length > 0){
               setNewSharedUrl(response.data.data[0].event_url);
            }else{
               setNewSharedUrl('');
            } 
         }
      })
      .catch((error) => {
          console.log(error);
      })
  }, [])
//   console.log(NewSharedUrl);

   const handleShareEvent = (params) => {
      var eventName = props.EventName;
      var a = window.location.origin + process.env.PUBLIC_URL;
      var url;
      const formattedEventName = eventName.replace(/\s/g, '_');
      // console.log(formattedEventName);
      if(NewSharedUrl !== ''){
         if (params === 'whatsapp') url = 'https://web.whatsapp.com/send?text=' + NewSharedUrl;
         if (params === 'facebook') url = 'https://www.facebook.com/login.php?text=' + NewSharedUrl;
         if (params === 'twitter') url = 'https://twitter.com/intent/tweet?text=' + NewSharedUrl;
         if (params === 'linkedin') url = 'https://www.linkedin.com/uas/login?text=' + NewSharedUrl;
      }else{
         if (params === 'whatsapp') url = 'https://web.whatsapp.com/send?text=' + a + '/e/' + formattedEventName;
         if (params === 'facebook') url = 'https://www.facebook.com/login.php?text=' + a + '/e/' + formattedEventName;
         if (params === 'twitter') url = 'https://twitter.com/intent/tweet?text=' + a + '/e/' + formattedEventName;
         if (params === 'linkedin') url = 'https://www.linkedin.com/uas/login?text=' + a + '/e/' + formattedEventName;
      }
    
      window.open(url, '_blank');
   };

   const handleCopyEvent = () => {
      var eventName = props.EventName;
      setCopy('Copied');
      var copyText = window.location.origin + process.env.PUBLIC_URL;
      // console.log(copyText);
      const formattedEventName = eventName.replace(/\s/g, '_');

      if(NewSharedUrl !== ''){
         copyText = NewSharedUrl;
      }else{
         copyText = copyText + "/e/" + formattedEventName;
      }
      navigator.clipboard.writeText(copyText);
   }

   useEffect(() => {
      const handleEscKeyPress = (event) => {
         if (event.key === 'Escape') {
            props.setShareEventFlag(false);
         }
      };
      document.addEventListener('keydown', handleEscKeyPress);
      return () => {
         document.removeEventListener('keydown', handleEscKeyPress);
      };
   }, [props, props.setShareEventFlag]);

   return (
      <div>
         <div className="modal is-visible" data-animation="slideInUp" onClick={(e) => { handleClose(e) }}>
            <div className="modal-dialog quickview__main--wrapper">
               <header className="modal-header quickview__header">
                  <button className="close-modal quickview__close--btn" aria-label="close modal" data-close onClick={() => {
                     props.setShareEventFlag(false);
                  }}>✕ </button>
               </header>
               <div className="quickview__inner">
                  <div className="row">
                     <div className="login__section">
                        <form >
                           <div className="login__section--inner" >
                              <div className="p-5">
                                 <div className="account__login--header mb-25">
                                    <h3 className="account__login--header__title mb-3">Share Event</h3>
                                 </div>
                                 <div _ngcontent-serverapp-c148="" className="px-2 py-2">
                                    <div _ngcontent-serverapp-c148="" className="platforms flex flex-wrap items-center justify-center">
                                       <div className="row">

                                       </div>

                                       <div className="row justify-content-center custom-share-event">

                                          <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 my-3">
                                             {/* WHATSAPP DIV */}
                                             <div _ngcontent-serverapp-c148="" onClick={(e) =>
                                                handleShareEvent('whatsapp')}>
                                                <div _ngcontent-serverapp-c148="" className="platform text-center cursor-pointer py-2 px-4 flex-1">
                                                   <svg xmlns="http://www.w3.org/2000/svg" width="22.239" height="22.984" viewBox="0 0 448 512">
                                                      <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                                                   </svg>
                                                   <br />
                                                   <span _ngcontent-serverapp-c148="" className="text-gray-700 text-sm block">WhatsApp</span>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 my-3">
                                             {/* FACEBOOK DIV */}
                                             <div _ngcontent-serverapp-c148="" onClick={(e) =>
                                                handleShareEvent('facebook')}>
                                                <div _ngcontent-serverapp-c148="" className="platform text-center cursor-pointer py-2 px-4 flex-1">
                                                   <svg xmlns="http://www.w3.org/2000/svg" width="11.239" height="20.984" viewBox="0 0 11.239 20.984">
                                                      <path id="Icon_awesome-facebook-f" data-name="Icon awesome-facebook-f" d="M11.575,11.8l.583-3.8H8.514V5.542A1.9,1.9,0,0,1,10.655,3.49h1.657V.257A20.2,20.2,0,0,0,9.371,0c-3,0-4.962,1.819-4.962,5.112V8.006H1.073v3.8H4.409v9.181H8.514V11.8Z" transform="translate(-1.073)" fill="currentColor"></path>
                                                   </svg>
                                                   <br />
                                                   <span _ngcontent-serverapp-c148="" className="text-gray-700 text-sm block">Facebook</span>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 my-3">
                                             {/* TWITTER */}
                                             <div _ngcontent-serverapp-c148="" onClick={(e) =>
                                                handleShareEvent('twitter')}>
                                                <div _ngcontent-serverapp-c148="" className="platform text-center cursor-pointer py-2 px-4 flex-1">
                                                   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="19.492" viewBox="0 0 24 19.492">
                                                      <path id="Icon_awesome-twitter" data-name="Icon awesome-twitter" d="M21.533,7.112c.015.213.015.426.015.64A13.9,13.9,0,0,1,7.553,21.746,13.9,13.9,0,0,1,0,19.538a10.176,10.176,0,0,0,1.188.061,9.851,9.851,0,0,0,6.107-2.1,4.927,4.927,0,0,1-4.6-3.411,6.2,6.2,0,0,0,.929.076,5.2,5.2,0,0,0,1.294-.167A4.919,4.919,0,0,1,.975,9.168V9.107A4.954,4.954,0,0,0,3.2,9.731,4.926,4.926,0,0,1,1.675,3.152,13.981,13.981,0,0,0,11.817,8.3,5.553,5.553,0,0,1,11.7,7.173a4.923,4.923,0,0,1,8.513-3.365A9.684,9.684,0,0,0,23.33,2.619,4.906,4.906,0,0,1,21.167,5.33,9.861,9.861,0,0,0,24,4.569a10.573,10.573,0,0,1-2.467,2.543Z" transform="translate(0 -2.254)" fill="currentColor"></path>
                                                   </svg>
                                                   <br />
                                                   <span _ngcontent-serverapp-c148="" className="text-gray-700 text-sm block">Twitter</span>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 my-3">
                                             {/* LINKED IN */}
                                             <div _ngcontent-serverapp-c148="" onClick={(e) =>
                                                handleShareEvent('linkedin')}>
                                                <div _ngcontent-serverapp-c148="" className="platform text-center cursor-pointer py-2 px-4 flex-1">
                                                   <svg xmlns="http://www.w3.org/2000/svg" width="19.419" height="19.419" viewBox="0 0 19.419 19.419">
                                                      <path id="Icon_awesome-linkedin-in" data-name="Icon awesome-linkedin-in" d="M4.347,19.419H.321V6.454H4.347ZM2.332,4.686A2.343,2.343,0,1,1,4.663,2.332,2.351,2.351,0,0,1,2.332,4.686ZM19.415,19.419H15.4V13.108c0-1.5-.03-3.433-2.093-3.433-2.093,0-2.414,1.634-2.414,3.325v6.42H6.869V6.454H10.73V8.223h.056A4.23,4.23,0,0,1,14.6,6.129c4.075,0,4.824,2.683,4.824,6.168v7.122Z" fill="currentColor"></path>
                                                   </svg>
                                                   <br />
                                                   <span _ngcontent-serverapp-c148="" className="text-gray-700 text-sm block">LinkedIn</span>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 my-3">
                                             <div _ngcontent-serverapp-c148="" onClick={(e) =>
                                                handleCopyEvent(e)}>
                                                <div _ngcontent-serverapp-c148="" className="platform text-center cursor-pointer p-2 pr-4 flex-1">
                                                   <svg width="22.239" height="22.984" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                      <path d="M208 0H332.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48zM48 128h80v64H64V448H256V416h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48z" />
                                                   </svg> <span _ngcontent-serverapp-c148="" className="text-gray-900 text-sm block ng-star-inserted"> {Copy}</span>
                                                   {/* <input _ngcontent-serverapp-c148="" type="text" id="event_link" className="copy_input" /> */}
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}
export default ShareEvent