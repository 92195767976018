import React, { useState, useEffect,useRef,useCallback } from 'react';
import axios from "./axios";
import { useNavigate, Link } from 'react-router-dom';
import LogoutPopup from './Components/LogoutPopup';
import Logo from "./img/logo/ytcr-logo.png";
import Userimg from "./img/user-icon.png";
import PopularCities from './PopularCities';
import Login from './Authorization/Login';
import WelcomePopup from './Components/WelcomePopup';
import RegistrationPopup from './Components/RegistrationPopup';
import SignUpPage from './Authorization/SignUpPage';
import ForgetPassword from './Authorization/ForgetPassword';
import SuccessForgetPassword from './Authorization/SuccessForgetPassword';
import ResetPassword from './Authorization/ResetPassword';
import SuccessResetPassword from './Authorization/SuccessResetPassword';
import EventPopup from './Components/EventPopup';
import UserVerification from './Authorization/UserVerification';
import SuccessUserVerification from './Authorization/SuccessUserVerification';
import { useDispatch } from "react-redux";

const HeaderSecond = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const token = localStorage.getItem("RacesToken");
    const [ShowPopularCitiesFlag, setShowPopularCitiesFlag] = useState(false);
    const [isToggled, setIsToggled] = useState(false);
    const [MsgFlag, setMsgFlag] = useState("");
    const [Logout, setLogout] = useState(false);
    const [UserFirstName, setUserFirstName] = React.useState('');
    const [UserLastName, setUserLastName] = React.useState('');
    const [UserEmail, setUserEmail] = React.useState('');
    const [isHeaderFixed, setIsHeaderFixed] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [SignUp, setSignUp] = React.useState(false);
    const [ForgotPasswordFlag, setForgotPasswordFlag] = useState(false);
    const [ResetPasswordEmail, setResetPasswordEmail] = useState("");
    const [SuccessResetPasswordFlag, setSuccessResetPasswordFlag] = useState(false);
    const [Welcome, setWelcome] = useState(false);
    const [Registration, setRegistration] = useState(false);
    const [LoginWishlist, setLoginWishlist] = useState('');
    const [UserProfile, setUserProfile] = React.useState('');
    const [Event, setEvent] = useState(false);
    const [OffCanvasMenu, setOffCanvasMenu] = useState(false);
    const [UserVerify, setUserVerify] = useState(false);
    const [UserVerifySuccess, setUserVerifySuccess] = useState(false);
    const [ShowCreateEventTab, setShowCreateEventTab] = useState(false);
    const [EventName, setEventName] = React.useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [EventData, setEventData] = useState([]);
    const [ShowSuggestion, setShowSuggestion] = useState(false);
    const DivRef = useRef(null);


    useEffect(() => {
        axios.post("events", {})
            .then((response) => {
                const eventData = response.data.data.EventData;
                // console.log(eventData);
                setEventData(eventData);
            })
            .catch((error) => {
                console.log(error);
            })
    }, [])

    const handleInputChange = (e) => {
        const value = e.target.value;
        setEventName(value);

        // Filter suggestions based on input value and limit to 5 elements
        const filteredSuggestions = EventData.filter(item =>
            item.name.toLowerCase().includes(value.toLowerCase())
        ).slice(0, 5); // Limit to 5 elements
        setSuggestions(filteredSuggestions);
        setShowSuggestion(true);
    }
    // console.log(suggestions);

    const handleSuggestionClick = (id, name) => {
        // alert(id)
        // setEventId(id); //If you want to select the event with its id
        setEventName(name)
        setSuggestions([]);
        if (name !== "") RedirectSearchEventPage("event_name", name);
    };

    const RedirectSearchEventPage = useCallback((filter_name, filter) => {
    //    console.log(filter_name, filter);
        setOffCanvasMenu(false);
        setEventName('');
        navigate('/events', { state: { "filter_name": filter_name, "filter": filter } });
    }, [navigate]);

    useEffect(() => {
        if (EventName !== "") {
            const handleKeyPress = (e) => {
                if (e.key === 'Enter') {
                    // console.log(EventName);
                    RedirectSearchEventPage("event_name", EventName);
                }
            };
            document.addEventListener('keydown', handleKeyPress);
            return () => {
                document.removeEventListener('keydown', handleKeyPress);
                document.body.style.overflow = 'auto';
            };
        }
    }, [EventName, RedirectSearchEventPage]);

    useEffect(() => {
        if (props.Login) {
            setOpen(true)
        }
    }, [props.Login])


    const handleCreateEvent = () => {
        const RacesToken = localStorage.getItem("RacesToken") ? localStorage.getItem("RacesToken") : "";
        // console.log(RacesToken);
        if (RacesToken !== "") {
            setEvent(true);
        } else {
            setOpen(true);
        }
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleSignUp = () => {
        setSignUp(true);
    };

    useEffect(() => {
        const handleScroll = () => {
            const threshold = 100;
            const scrolled = window.scrollY;
            if (scrolled > threshold) {
                setIsHeaderFixed(true);
            } else {
                setIsHeaderFixed(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleCloseLogoutPopup = () => {
        setLogout(false);
    };

    const toggle = () => {
        setIsToggled(prevState => !prevState);
    };

    // ORGANIZING EVENTS TOGGLE
    const [isToggleOrganizingEvent, setIsToggleOrganizingEvent] = useState(false);

    const ToggleOrganizingEvent = () => {
        setIsToggleOrganizingEvent(prevState => !prevState);
    };

    // ATTENDING EVENTS TOGGLE
    const [isToggleAttendingEvent, setIsToggleAttendingEvent] = useState(false);

    const ToggleAttendingEvent = () => {
        setIsToggleAttendingEvent(prevState => !prevState);
    };

    const handleLogout = () => {
        localStorage.removeItem('ID');
        localStorage.removeItem('EMAIL');
        localStorage.removeItem('MOBILE');
        localStorage.removeItem('RacesToken');
        localStorage.removeItem('EventDetailsId');
        localStorage.removeItem('EventViewFlag');
        localStorage.removeItem('EventId');
        localStorage.removeItem('EventType');
        localStorage.removeItem('LoginAsOrganiser');
        localStorage.removeItem("Access_Right_Flag");
        localStorage.removeItem("OrgUserAccessModules");
        localStorage.removeItem("Role_Name");

        // toggle();
        const CountryCode = localStorage.getItem('CountryCode');
        const CountryName = localStorage.getItem('Country');
        navigate('/' + CountryCode + '/' + CountryName);
    };

    useEffect(() => {
        if (token) {
            axios.get("get_profile")
                .then((response) => {
                    // console.log(response.data.data.userData);
                    // setUserdata(response.data.data.userData[0]);
                    setShowCreateEventTab(response.data.data.userData[0].show_create_event_tab);
                    setUserFirstName(response.data.data.userData[0].firstname);
                    setUserLastName(response.data.data.userData[0].lastname);
                    setUserProfile(response.data.data.userData[0].profile_pic);
                    setUserEmail(response.data.data.userData[0].email);
                })
                .catch((error) => {
                    // console.log(error);
                })
        }
    }, [token])

    const handleBackToHome = () => {
        localStorage.removeItem('EventViewFlag');
        localStorage.removeItem('EventDetailsId');
        localStorage.removeItem('SearchEventPageFlag');
        localStorage.removeItem('EventId');
        localStorage.removeItem('EventType');
        const CountryCode = localStorage.getItem('CountryCode');
        const CountryName = localStorage.getItem('Country');
        navigate('/' + CountryCode + '/' + CountryName);
    }
    const handleCloseWelcomePopup = () => {
        setWelcome(false);
    };
    const handleCloseRegistrationPopup = () => {
        setRegistration(false);
        setUserVerify(true);
    };
    const handleCloseEventPopup = () => {
        setEvent(false);
    };

    const handleCloseSuccessUserVerification = () => {
        setUserVerifySuccess(false)
    }

    const [CreateEventTab, setCreateEventTab] = useState(false);
    useEffect(() => {
        // localStorage.removeItem("EventId");
        const EventType = localStorage.getItem("EventType") ? localStorage.getItem("EventType") : 0;
        if (EventType === 0) {
            setCreateEventTab(true);
        }
    }, [])
    // console.log(CreateEventTab,ShowCreateEventTab);

    const handleProfile = () => {
        setIsToggled(!isToggled);
    };

    // Event listener for outside click
    const handleClickOutside = (event) => {
        if (isToggled && !event.target.closest('.dropdown__currency')) {
            setIsToggled(false);
        }
    };

    // Event listener for Esc key
    const handleEscKey = (event) => {
        if (isToggled && event.key === 'Escape') {
            setIsToggled(false);
            // setOpen(false);
            // props.setLogin(false);
        }
    };


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleEscKey);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [isToggled]);
    // console.log(props);
    
    useEffect(() => {
        if(open === false && props.ClosedFlag && props.ClosedFlag === 1){
             props.setLogin(false);
        }
    }, [open]);
    
    // console.log(props);
    const LoginAsOrganiserFlag = localStorage.getItem("LoginAsOrganiser") ? localStorage.getItem("LoginAsOrganiser") : '';
    const handleBackToAdmin = () => {  
        if(LoginAsOrganiserFlag && LoginAsOrganiserFlag === '1'){
            //--------------- Logout privious user
            localStorage.removeItem("Token");
            localStorage.removeItem("Name");
            localStorage.removeItem("Modules");
            localStorage.removeItem("Id");
            localStorage.removeItem("booking_pay_id");
            localStorage.removeItem("RacesToken");
            localStorage.removeItem("LoginAsOrganiser");
            localStorage.removeItem("Access_Right_Flag");
            localStorage.removeItem("OrgUserAccessModules");
            localStorage.removeItem("Role_Name");
            // dispatch(token1(""));
            window.close();
        }
    };

    const my_profile_svg = "M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z";

    const my_event_svg = "m80-80 200-560 360 360L80-80Zm132-132 282-100-182-182-100 282Zm370-246-42-42 224-224q32-32 77-32t77 32l24 24-42 42-24-24q-14-14-35-14t-35 14L582-458ZM422-618l-42-42 24-24q14-14 14-34t-14-34l-26-26 42-42 26 26q32 32 32 76t-32 76l-24 24Zm80 80-42-42 144-144q14-14 14-35t-14-35l-64-64 42-42 64 64q32 32 32 77t-32 77L502-538Zm160 160-42-42 64-64q32-32 77-32t77 32l64 64-42 42-64-64q-14-14-35-14t-35 14l-64 64ZM212-212Z";
    
    const organiser_svg = "M120-120v-560h160v-160h400v320h160v400H520v-160h-80v160H120Zm80-80h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 320h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 480h80v-80h-80v80Zm0-160h80v-80h-80v80Z"
    
    //--------------- access rights
    const AccessRightFlag = localStorage.getItem("Access_Right_Flag");
    const OrgUserAccessModules = localStorage.getItem("OrgUserAccessModules") ? JSON.parse(localStorage.getItem("OrgUserAccessModules")) : '';
    // console.log(AccessRightFlag);

    return (
        <>
            {open ? <Login setOpen={setOpen} setForgotPasswordFlag={setForgotPasswordFlag} setResetPasswordEmail={setResetPasswordEmail} SuccessResetPasswordFlag={setSuccessResetPasswordFlag} setWelcome={setWelcome} setMsgFlag={setMsgFlag} setLoginWishlist={setLoginWishlist} setLogin={props.setLogin} setUserVerify={setUserVerify} setSignUp={setSignUp}/> : null}

            {Welcome ? <WelcomePopup setWelcome={setWelcome} onClose={handleCloseWelcomePopup} setOpen={setOpen} setForgotPasswordFlag={setForgotPasswordFlag} setResetPasswordEmail={setResetPasswordEmail} SuccessResetPasswordFlag={setSuccessResetPasswordFlag} MsgFlag={MsgFlag} setMsgFlag={setMsgFlag} /> : null}

            {Registration ? <RegistrationPopup setRegistration={setRegistration} onClose={handleCloseRegistrationPopup} setOpen={setOpen} setForgotPasswordFlag={setForgotPasswordFlag} setResetPasswordEmail={setResetPasswordEmail} SuccessResetPasswordFlag={setSuccessResetPasswordFlag} MsgFlag={MsgFlag} setMsgFlag={setMsgFlag} setUserVerify={setUserVerify} /> : null}

            {Logout ? <LogoutPopup setLogout={setLogout} onClose={handleCloseLogoutPopup} setOpen={setOpen} setForgotPasswordFlag={setForgotPasswordFlag} setResetPasswordEmail={setResetPasswordEmail} SuccessResetPasswordFlag={setSuccessResetPasswordFlag} MsgFlag={MsgFlag} setMsgFlag={setMsgFlag} /> : null}

            {SignUp ? <SignUpPage setSignUp={setSignUp} setWelcome={setWelcome} setMsgFlag={setMsgFlag} setRegistration={setRegistration} setUserVerify={setUserVerify}  setOpen={setOpen}/> : null}

            {ForgotPasswordFlag ? <ForgetPassword setForgotPasswordFlag={setForgotPasswordFlag} setResetPasswordEmail={setResetPasswordEmail} setOpen={setOpen} SuccessResetPasswordFlag={setSuccessResetPasswordFlag} setWelcome={setWelcome} setRegistration={setRegistration} /> : null}

            {ResetPasswordEmail !== "" ? <SuccessForgetPassword setOpen={setOpen} setResetPasswordEmail={setResetPasswordEmail} ResetPasswordEmail={ResetPasswordEmail} setForgotPasswordFlag={setForgotPasswordFlag} SuccessResetPasswordFlag={setSuccessResetPasswordFlag} setWelcome={setWelcome} setRegistration={setRegistration} /> : null}

            {props.ResetPasswordFlag ? <ResetPassword setOpen={setOpen} setForgotPasswordFlag={setForgotPasswordFlag} setResetPasswordEmail={setResetPasswordEmail} ResetPasswordEmail={ResetPasswordEmail} ResetPasswordToken={props.ResetPasswordToken} SuccessResetPasswordFlag={setSuccessResetPasswordFlag} setWelcome={setWelcome} setRegistration={setRegistration} /> : null}

            {SuccessResetPasswordFlag ? <SuccessResetPassword setOpen={setOpen} setForgotPasswordFlag={setForgotPasswordFlag} setResetPasswordEmail={setResetPasswordEmail} ResetPasswordEmail={ResetPasswordEmail} ResetPasswordToken={props.ResetPasswordToken} SuccessResetPasswordFlag={setSuccessResetPasswordFlag} setWelcome={setWelcome} setRegistration={setRegistration} /> : null}

            {Logout ? <LogoutPopup setLogout={setLogout} onClose={handleCloseLogoutPopup} MsgFlag={MsgFlag} setMsgFlag={setMsgFlag} /> : null}

            {Event ? <EventPopup setEvent={setEvent} onClose={handleCloseEventPopup} setOpen={setOpen} setForgotPasswordFlag={setForgotPasswordFlag} setResetPasswordEmail={setResetPasswordEmail} SuccessResetPasswordFlag={setSuccessResetPasswordFlag} MsgFlag={MsgFlag} setMsgFlag={setMsgFlag} /> : null}

            {UserVerify ? <UserVerification UserVerify={UserVerify} setUserVerify={setUserVerify} setUserVerifySuccess={setUserVerifySuccess} /> : null}

            {UserVerifySuccess ? <SuccessUserVerification onClose={handleCloseSuccessUserVerification} /> : null}

            {/* Start header area */}
            {/* <header className="header__section header__transparent" onClick={(e) => { handleClose(e) }}> */}
            <header className={`header__section ${isHeaderFixed ? 'header__fixed' : ''}`} >
                {/* Start main header */}
                <div className="main__header header__sticky">
                    <div className="container" >
                        <div className="main__header--inner position__relative d-flex justify-content-between align-items-center">
                            {/* start mobile responsive menu iocn */}
                            <div className="offcanvas__header--menu__open ">
                                <div className="offcanvas__header--menu__open--btn" onClick={(e) => setOffCanvasMenu(true)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="ionicon offcanvas__header--menu__open--svg" viewBox="0 0 512 512"><path fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32" d="M80 160h352M80 256h352M80 352h352" /></svg>
                                    <span className="visually-hidden">Offcanvas Menu Open</span>
                                </div>
                            </div>
                            {/* End mobile responsive menu iocn */}

                            {/* start main logo */}
                            <div className="main__logo py-4 titleHover_SETUP cursor-pointer">
                                <h1 className="main__logo--title" ><div className="main__logo--link" onClick={(e) => handleBackToHome(e)}><img className="main__logo--img" src={`${Logo}`} style={{ "height": "45px" }} alt="logo-img" /></div></h1>
                            </div>
                            {/* End main logo */}

                            {/* Start City | create event btn | Login | Signup */}
                            <div className="header__account">
                                <ul className="d-flex align-items-center gap-5">
                                    {/* Start create event button */}
                                    {LoginAsOrganiserFlag && LoginAsOrganiserFlag === '1' ?
                                        <li className="header__account--items m-0 create-event">
                                            <div className="crate-event-btn d-flex align-items-center gap-2 hand_cursor"  href="#" rel="nofollow" onClick={(e) => handleBackToAdmin()}  >
                                                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 -960 960 960" width="24" height="24"><path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z"></path></svg>
                                                <p className="m-0 ">Back To Admin</p></div>
                                        </li>
                                    : null }

                                    {CreateEventTab === true && ShowCreateEventTab === true ?
                                        <>
                                        {AccessRightFlag === '1' ?
                                            <>
                                                {OrgUserAccessModules && OrgUserAccessModules.Event === 2 ?
                                                    <li className="header__account--items m-0 create-event">
                                                        <div className="crate-event-btn d-flex align-items-center gap-2 hand_cursor" onClick={(e) => handleCreateEvent()} href="#" rel="nofollow">
                                                            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="24" height="24"><path d="m19,2h-1v-1c0-.552-.448-1-1-1s-1,.448-1,1v1h-8v-1c0-.552-.448-1-1-1s-1,.448-1,1v1h-1C2.243,2,0,4.243,0,7v12c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5V7c0-2.757-2.243-5-5-5Zm-14,2h14c1.654,0,3,1.346,3,3v1H2v-1c0-1.654,1.346-3,3-3Zm14,18H5c-1.654,0-3-1.346-3-3v-9h20v9c0,1.654-1.346,3-3,3Zm-2.603-6.648c0,.379-.264.698-.566.866l-1.564.87.694,1.893c.134.367.013.778-.299,1.013h0c-.319.24-.759.237-1.075-.007l-1.556-1.203-1.556,1.203c-.316.244-.756.247-1.075.007h0c-.312-.235-.433-.646-.299-1.013l.694-1.893-1.564-.87c-.302-.168-.566-.487-.566-.866,0-.321.279-.676.731-.676h2.247l.596-2.283c.094-.362.419-.614.792-.621.373.007.698.259.792.621l.596,2.283h2.247c.452,0,.731.354.731.676Z"></path></svg>
                                                            <p className="m-0 ">Design Your Event</p></div>
                                                    </li>
                                                : null }
                                            </>
                                        : 
                                            <li className="header__account--items m-0 create-event">
                                                <div className="crate-event-btn d-flex align-items-center gap-2 hand_cursor" onClick={(e) => handleCreateEvent()} href="#" rel="nofollow">
                                                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="24" height="24"><path d="m19,2h-1v-1c0-.552-.448-1-1-1s-1,.448-1,1v1h-8v-1c0-.552-.448-1-1-1s-1,.448-1,1v1h-1C2.243,2,0,4.243,0,7v12c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5V7c0-2.757-2.243-5-5-5Zm-14,2h14c1.654,0,3,1.346,3,3v1H2v-1c0-1.654,1.346-3,3-3Zm14,18H5c-1.654,0-3-1.346-3-3v-9h20v9c0,1.654-1.346,3-3,3Zm-2.603-6.648c0,.379-.264.698-.566.866l-1.564.87.694,1.893c.134.367.013.778-.299,1.013h0c-.319.24-.759.237-1.075-.007l-1.556-1.203-1.556,1.203c-.316.244-.756.247-1.075.007h0c-.312-.235-.433-.646-.299-1.013l.694-1.893-1.564-.87c-.302-.168-.566-.487-.566-.866,0-.321.279-.676.731-.676h2.247l.596-2.283c.094-.362.419-.614.792-.621.373.007.698.259.792.621l.596,2.283h2.247c.452,0,.731.354.731.676Z"></path></svg>
                                                    <p className="m-0 ">Design Your Event</p></div>
                                            </li>
                                        }
                                        </> 
                                    : null}
                                    {/* End create event button */}

                                    {/* Start Login | Signup */}
                                    <ul className="d-flex align-items-center gap-4">

                                        <li className="menu-login-signup">
                                            <div className="d-flex gap-2 align-items-center hand_cursor">
                                                {!(token) ?
                                                    <>
                                                        <p className="m-0" onClick={(e) => handleOpen(e)}>Login</p>
                                                        <p className="m-0">|</p>
                                                        <p className="m-0" onClick={(e) => handleSignUp(e)}>Signup</p>
                                                    </>
                                                    :
                                                    <>
                                                        <li className="language__currency--list m-0">
                                                            <div className="account__currency--link style2 gap-2" onClick={toggle}>
                                                                <img src={UserProfile || Userimg}
                                                                    alt="" style={{ borderRadius: "50%", height: "45px", width: "45px", objectFit: "cover" }} />
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" /></svg>
                                                            </div>

                                                            {/* add active class */}
                                                            <div className={isToggled ? "dropdown__currency active" : "dropdown__currency"} style={{maxWidth:"400px"}}>
                                                                <div className="p-3">
                                                                    <div className="dr-userprofile pb-3">
                                                                        <div className="p-2">
                                                                            <p className="mb-3" style={{ overflowWrap: 'anywhere' }}><strong>{UserFirstName.toUpperCase() + "  " + UserLastName.toUpperCase()} </strong></p>
                                                                            <p className="mb-0" style={{ overflowWrap: 'anywhere' }}>{UserEmail}</p>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    {AccessRightFlag === '1' ?
                                                                        <>
                                                                           
                                                                            {OrgUserAccessModules.MyProfile === 1 || OrgUserAccessModules.MyProfile === 2 ?

                                                                            <>
                                                                              
                                                                                <Link className="dr-li " to={'/profile'}>
                                                                                        <div className="">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d={my_profile_svg} /></svg> My Profile
                                                                                        </div>
                                                                                </Link>
                                                                                <hr className="m-1" />
                                                                            </>
                                                                            : null }
                                                                      </>
                                                                    : 
                                                                    <>
                                                                        
                                                                        <Link className="dr-li " to={'/profile'}>
                                                                            <div className="">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d={my_profile_svg} /></svg> My Profile
                                                                            </div>
                                                                        </Link>

                                                                        <hr className="m-1" />
                                                                    </>
                                                                        
                                                                    }

                                                                    

                                                                    {AccessRightFlag === '1' ?
                                                                        <>
                                                                           {OrgUserAccessModules.Event === 1 || OrgUserAccessModules.Event === 2 ?
                                                                                <>
                                                                                    
                                                                                    <Link className="dr-li " to={'/edit_event'}>
                                                                                        <div className="">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d={my_event_svg} /></svg> My Events
                                                                                        </div>
                                                                                    </Link>

                                                                                    <hr className="m-1" />
                                                                                </>
                                                                              
                                                                            : null }
                                                                      </>
                                                                    : 
                                                                    <>
                                                                       
                                                                        <Link className="dr-li " to={'/edit_event'}>
                                                                            <div className="">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d={my_event_svg} /></svg> My Events
                                                                            </div>
                                                                        </Link>

                                                                        <hr className="m-1" />
                                                                    </>
                                                                       
                                                                    }


                                                                    {AccessRightFlag === '1' ?
                                                                        <>
                                                                            
                                                                            {OrgUserAccessModules.Organiser === 1 || OrgUserAccessModules.Organiser === 2 ?
                                                                                <>
                                                                                  
                                                                                   <Link className="dr-li " to={'/organizer'}>
                                                                                        <div className="">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d={organiser_svg} /></svg> Organiser Profile
                                                                                        </div>
                                                                                    </Link>

                                                                                    <hr className="m-1" />
                                                                                </>
                                                                               
                                                                            : null }
                                                                      </>
                                                                    : 
                                                                        <>
                                                                           
                                                                            <Link className="dr-li " to={'/organizer'}>
                                                                                <div className="">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d={organiser_svg} /></svg> Organiser Profile
                                                                                </div>
                                                                            </Link>

                                                                            <hr className="m-1" />
                                                                        </>
                                                                       
                                                                    }

                                                                    <Link className="dr-li " to={'/wishlist'}>
                                                                        <div className="">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z" /></svg> My Favourites
                                                                        </div>
                                                                    </Link>

                                                                    <hr className="m-1" />
                                                                    <Link className="dr-li " to={'/my_bookings'}>
                                                                        <div className="">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm0-160q17 0 28.5-11.5T520-480q0-17-11.5-28.5T480-520q-17 0-28.5 11.5T440-480q0 17 11.5 28.5T480-440Zm0-160q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm320 440H160q-33 0-56.5-23.5T80-240v-160q33 0 56.5-23.5T160-480q0-33-23.5-56.5T80-560v-160q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v160q-33 0-56.5 23.5T800-480q0 33 23.5 56.5T880-400v160q0 33-23.5 56.5T800-160Zm0-80v-102q-37-22-58.5-58.5T720-480q0-43 21.5-79.5T800-618v-102H160v102q37 22 58.5 58.5T240-480q0 43-21.5 79.5T160-342v102h640ZM480-480Z" /></svg> Registration Tracker
                                                                        </div>
                                                                    </Link>

                                                                    <hr className="m-1" />

                                                                    <div className="dr-li" onClick={(e) => handleLogout()} >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-440v-400h80v400h-80Zm40 320q-74 0-139.5-28.5T226-226q-49-49-77.5-114.5T120-480q0-80 33-151t93-123l56 56q-48 40-75 97t-27 121q0 116 82 198t198 82q117 0 198.5-82T760-480q0-64-26.5-121T658-698l56-56q60 52 93 123t33 151q0 74-28.5 139.5t-77 114.5q-48.5 49-114 77.5T480-120Z" /></svg> <p className="mx-1">Logout</p>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </li>


                                                    </>
                                                }
                                            </div>
                                            <ul className="header__sub--menu">
                                                <li className="header__sub--menu__items"><a href="#" className="header__sub--menu__link">Profile</a></li>
                                                <li className="header__sub--menu__items"><a href="#" className="header__sub--menu__link">logout</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                    {/* End Login | Signup */}
                                </ul>
                            </div>
                            {/* End City | create event btn | Login | Signup */}

                        </div>
                    </div>
                </div>
                {/* End main header */}

                {/* Start Offcanvas header menu */}
                <div className={OffCanvasMenu ? "offcanvas__header open" : "offcanvas__header"} tabIndex="-1">
                    <div className="offcanvas__inner">
                        <div className="offcanvas__logo">
                            <a className="offcanvas__logo_link" href="#">
                                <img src={`${Logo}`} style={{ "height": "45px" }} alt="YooTooCanRun Logo" />
                            </a>
                            <button className="offcanvas__close--btn" aria-label="offcanvas close btn" onClick={(e) => setOffCanvasMenu(false)}>close</button>
                        </div>
                        <nav className="offcanvas__menu">
                            <ul className="offcanvas__menu_ul">

                            <li className="offcanvas__menu_li">
                                    <div className="offcanvas_account--items p-3">
                                        <form className="d-flex header__search--form gap-3" action="#">
                                            <div className="header__account--items  header__account--search__items event-location m-0 " onClick={(e) => setShowPopularCitiesFlag(true)}>
                                                <a className="header__account--btn search__open--btn d-flex align-items-center" href="#">
                                                    <svg className="header__search--button__svg" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                                                        <path d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z" />
                                                    </svg>
                                                    <span className="event-location-txt">Mumbai</span>
                                                    <svg className="event-location-arrow" xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960" width="22">
                                                        <path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" />
                                                    </svg>
                                                </a>
                                            </div>
                                            {/* start Search box */}
                                            <div className="header__search--box w-100">
                                                <label>
                                                    {/* <input className="header__search--input" placeholder="Search for events..." type="text" /> */}
                                                    <input className="header__search--input" placeholder="Search for events..." type="text" value={EventName} onChange={(e) => { setEventName(e.target.value); handleInputChange(e) }} />
                                                    <div className="autocomplete">
                                                        {ShowSuggestion ?
                                                            <ul ref={DivRef}>
                                                                {
                                                                    (suggestions.length > 0) ?
                                                                        suggestions.map((item) => {
                                                                            // console.log(item);
                                                                            return (
                                                                                <>
                                                                                    <li style={{lineHeight: '25px'}} key={item.id} onClick={(e) => handleSuggestionClick(item.id, item.name)}>{item.name} </li>
                                                                                    <hr className='m-0 my-1'/>
                                                                                </>
                                                                                
                                                                            )
                                                                        }) : null
                                                                }
                                                            </ul>
                                                            : null
                                                        }
                                                    </div>
                                                </label>
                                                <button className="header__search--button bg__secondary text-white" aria-label="search button" type="submit">
                                                    <svg className="header__search--button__svg" xmlns="http://www.w3.org/2000/svg" width="27.51" height="26.443" viewBox="0 0 512 512">
                                                        <path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32"></path>
                                                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32" d="M338.29 338.29L448 448"></path>
                                                    </svg>
                                                </button>
                                                
                                            </div>
                                            {/* end Search box */}
                                        </form>
                                    </div>
                                </li>

                                {!(token) ?
                                    <>
                                        <div className="p-3">
                                            <div className="my-4 d-flex align-items-center justify-content-between" onClick={(e) => handleOpen(e)}>
                                                <div className="d-flex gap-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="0 -960 960 960" width="28"><path d="M480-120v-80h280v-560H480v-80h280q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H480Zm-80-160-55-58 102-102H120v-80h327L345-622l55-58 200 200-200 200Z" /></svg>
                                                    <p className="h4">Login</p>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="0 -960 960 960" width="28"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" /></svg>
                                            </div>
                                            <hr className="m-1" />
                                            <div className="my-4 d-flex align-items-center justify-content-between" onClick={(e) => handleSignUp(e)}>
                                                <div className="d-flex gap-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="0 -960 960 960" width="28"><path d="M240-160q-33 0-56.5-23.5T160-240q0-33 23.5-56.5T240-320q33 0 56.5 23.5T320-240q0 33-23.5 56.5T240-160Zm0-240q-33 0-56.5-23.5T160-480q0-33 23.5-56.5T240-560q33 0 56.5 23.5T320-480q0 33-23.5 56.5T240-400Zm0-240q-33 0-56.5-23.5T160-720q0-33 23.5-56.5T240-800q33 0 56.5 23.5T320-720q0 33-23.5 56.5T240-640Zm240 0q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Zm240 0q-33 0-56.5-23.5T640-720q0-33 23.5-56.5T720-800q33 0 56.5 23.5T800-720q0 33-23.5 56.5T720-640ZM480-400q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm40 240v-123l221-220q9-9 20-13t22-4q12 0 23 4.5t20 13.5l37 37q8 9 12.5 20t4.5 22q0 11-4 22.5T863-380L643-160H520Zm300-263-37-37 37 37ZM580-220h38l121-122-18-19-19-18-122 121v38Zm141-141-19-18 37 37-18-19Z" /></svg>
                                                    <p className="h4">Signup</p>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="0 -960 960 960" width="28"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" /></svg>
                                            </div>

                                        </div>
                                    </>
                                    :
                                    <>
                                         <div>
                                            <li className="offcanvas__menu_li user-profile mt-3">

                                                <div className="d-flex flex-column align-items-center p-3 my-2 text-center">
                                                    <img src={UserProfile || Userimg} alt="" style={{ borderRadius: "50%", height: "45px", width: "45px", objectFit: "cover" }} />
                                                    <h4 className="text-white mb-1">{UserFirstName.toUpperCase() + "  " + UserLastName.toUpperCase()}</h4>
                                                    <small className="text-white" style={{overflowWrap: "anywhere"}}>{UserEmail}</small>
                                                </div>
                                            </li>

                                            <div className="p-3">

                                            <Link className="dr-li my-3" to={'/profile'}>
                                                                        <div className="">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z" /></svg> My Profile
                                                                        </div>
                                                                    </Link>

                                                                    <hr className="m-1" />

                                                                    <Link className="dr-li my-3" to={'/edit_event'}>
                                                                        <div className="">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m80-80 200-560 360 360L80-80Zm132-132 282-100-182-182-100 282Zm370-246-42-42 224-224q32-32 77-32t77 32l24 24-42 42-24-24q-14-14-35-14t-35 14L582-458ZM422-618l-42-42 24-24q14-14 14-34t-14-34l-26-26 42-42 26 26q32 32 32 76t-32 76l-24 24Zm80 80-42-42 144-144q14-14 14-35t-14-35l-64-64 42-42 64 64q32 32 32 77t-32 77L502-538Zm160 160-42-42 64-64q32-32 77-32t77 32l64 64-42 42-64-64q-14-14-35-14t-35 14l-64 64ZM212-212Z" /></svg> My Events
                                                                        </div>
                                                                    </Link>

                                                                    <hr className="m-1" />

                                                                    <Link className="dr-li my-3" to={'/organizer'}>
                                                                        <div className="">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M120-120v-560h160v-160h400v320h160v400H520v-160h-80v160H120Zm80-80h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 320h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 480h80v-80h-80v80Zm0-160h80v-80h-80v80Z" /></svg> Organiser Profile
                                                                        </div>
                                                                    </Link>

                                                                    <hr className="m-1" />

                                                                    <Link className="dr-li my-3" to={'/wishlist'}>
                                                                        <div className="">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z" /></svg> My Favourites
                                                                        </div>
                                                                    </Link>

                                                                    <hr className="m-1" />
                                                                    <Link className="dr-li my-3" to={'/my_bookings'}>
                                                                        <div className="">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm0-160q17 0 28.5-11.5T520-480q0-17-11.5-28.5T480-520q-17 0-28.5 11.5T440-480q0 17 11.5 28.5T480-440Zm0-160q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm320 440H160q-33 0-56.5-23.5T80-240v-160q33 0 56.5-23.5T160-480q0-33-23.5-56.5T80-560v-160q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v160q-33 0-56.5 23.5T800-480q0 33 23.5 56.5T880-400v160q0 33-23.5 56.5T800-160Zm0-80v-102q-37-22-58.5-58.5T720-480q0-43 21.5-79.5T800-618v-102H160v102q37 22 58.5 58.5T240-480q0 43-21.5 79.5T160-342v102h640ZM480-480Z" /></svg> Registration Tracker
                                                                        </div>
                                                                    </Link>

                                                                    <hr className="m-1" />
                                                                    <div className="dr-li my-3  d-flex align-items-center" onClick={(e) => handleLogout()} >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-440v-400h80v400h-80Zm40 320q-74 0-139.5-28.5T226-226q-49-49-77.5-114.5T120-480q0-80 33-151t93-123l56 56q-48 40-75 97t-27 121q0 116 82 198t198 82q117 0 198.5-82T760-480q0-64-26.5-121T658-698l56-56q60 52 93 123t33 151q0 74-28.5 139.5t-77 114.5q-48.5 49-114 77.5T480-120Z" /></svg> <p className="mx-1">Logout</p>
                                                                    </div>

                                            </div>
                                        </div>
                                    </>
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
                {/* End Offcanvas header menu */}

                {
                    ShowPopularCitiesFlag ? <PopularCities ShowPopularCitiesFlag={ShowPopularCitiesFlag} setShowPopularCitiesFlag={setShowPopularCitiesFlag} setHeaderInputCity={props.setHeaderInputCity} setSearchFlag={props.setSearchFlag} HeaderInputCity={props.HeaderInputCity} locDetected={props.locDetected} setCountrySearchFlag={props.setCountrySearchFlag} /> : null
                }
                {/* <PopularCities /> */}
            </header>
            {/* End header area */}



        </>
    )
}

export default HeaderSecond
