import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: '',
};

export const TokenSlice = createSlice({
  name: "tokenValue",
  initialState: { value: initialState },
  reducers: {
    token: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { token } = TokenSlice.actions;

export default TokenSlice.reducer;
