import HeaderSecond from '../HeaderSecond'
import BreadcrumbBg from '../img/banner/breadcrumb-bg.jpg';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../axios'
import MyProfile from './MyProfile';
import EditMyProfile from './EditMyProfile';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NewOrgUser from './NewOrgUser';
import NewUser from './NewUser';
import Loader from '../Loader';
import {UserAccessRights} from '../Custom';

const Profile = () => {
    const navigate = useNavigate();
    const [Section, setSection] = useState('my-profile');
    const [ProfileApiCall, setProfileApiCall] = useState(true)
    localStorage.removeItem('EventId');
    localStorage.removeItem('EventType');
    const [ProfileCompletionPercentage, setProfileCompletionPercentage] = useState(0);
    const [ProgressbarClass, setProgressbarClass] = useState('');
    const [loader, setLoader] = useState(false);
    const [DownloadAthletcard, setDownloadAthletcard] = useState(false);
    const UserId = localStorage.getItem("ID")
    const [EventAthletCard, setEventAthletCard] = useState(0);
    
    const handleBackToHome = () => {
        localStorage.removeItem('EventId');
        localStorage.removeItem('EventType');
        const CountryCode = localStorage.getItem('CountryCode');
        const CountryName = localStorage.getItem('Country');
        navigate('/' + CountryCode + '/' + CountryName);
    }

    const [Userdata, setUserdata] = useState([]);
    const [Mobile, setMobile] = useState('');
    const [Email, setEmail] = useState('');
    const [Firstname, setFirstname] = useState('');
    const [Lastname, setLastname] = useState('');


    useEffect(() => {
        if (ProfileApiCall) {
            axios.get("get_profile")
                .then((response) => {
                    // console.log(response.data);
                    setUserdata(response.data.data.userData[0]);
                    setMobile(response.data.data.userData[0].mobile);
                    setEmail(response.data.data.userData[0].email);
                    setFirstname(response.data.data.userData[0].firstname);
                    setLastname(response.data.data.userData[0].lastname);

                    var ProfilePercentage = response.data.data.ProfileCompletionPercentage;
                    // console.log(ProfilePercentage);
                    setProfileCompletionPercentage(ProfilePercentage)

                    if (ProfilePercentage < 25)
                        setProgressbarClass('progress progress1');
                    // else if (ProfilePercentage > 20 && ProfilePercentage <= 40)
                    //     setProgressbarClass('progress progress2');
                    else if (ProfilePercentage > 40 && ProfilePercentage <= 50)
                        setProgressbarClass('progress progress3');
                    else if (ProfilePercentage > 60 && ProfilePercentage <= 75)
                        setProgressbarClass('progress progress4');
                    // else if (ProfilePercentage > 80 && ProfilePercentage <= 99)
                    //     setProgressbarClass('progress progress5');
                    else if (ProfilePercentage > 99 && ProfilePercentage <= 100)
                        setProgressbarClass('progress progress6');
                    
                    setEventAthletCard(response.data.data.EventAthletData);

                    setProfileApiCall(false);

                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [ProfileApiCall])
    // console.log(EventAthletCard);

    // ------------------------------------------------------------------------------------------------
    const [isVisible, setIsVisible] = useState(false);

    // Function to handle scroll event and toggle button visibility
    const handleScroll = () => {
        const scrollY = window.scrollY;
        if (scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Function to handle smooth scroll to top
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    // Add scroll event listener when component mounts
    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    // console.log(Section);

    //-------------- To check user access rights
    const AccessRightFlag = localStorage.getItem("Access_Right_Flag");
    useEffect(() => {
        if(AccessRightFlag === '1'){
            const access = UserAccessRights({module_name: "MyProfile"});
            if(access === true){
            alert("You don't have any access to this module");
            navigate('/');
            }
        }
    }, []);

    useEffect(() => {
       
        if (DownloadAthletcard) {
            setLoader(true);
            const payload = {
                "user_id": UserId,
            };
            // console.log(payload);
            axios
                .post("athleteCard", payload)
                .then((response) => {
                    // console.log(response.data.data.data);
                    if (response.status === 200) {
                        setDownloadAthletcard(false);
                        setLoader(false);
                        var link = document.createElement('a');
                        document.body.appendChild(link);
                        link.target = "_blank";
                        link.href = response.data.data.pdf_path;
                        link.download = '';
                        link.click();
                    }
                })
                .catch(() => {
                    // console.log('error');
                    setLoader(false);
                });
                setDownloadAthletcard(false);
        }
    }, [DownloadAthletcard]);


    return (
        <>
            <HeaderSecond />
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            <main className="main__content_wrapper">

                {/* <div style={{ backgroundImage: `url(${LoaderImg})` }}></div> */}

                {/* Start breadcrumb section */}
                <section className="breadcrumb__section breadcrumb__bg" style={{ backgroundImage: `url(${BreadcrumbBg})` }} >
                    <div className="container">
                        <div className="row row-cols-1 ">
                            <div className="col">
                                <div className="breadcrumb__content">
                                    <h1 className="breadcrumb__content--title text-white mb-10">
                                        Profile
                                    </h1>
                                    <ul className="breadcrumb__content--menu d-flex">
                                        <li className="breadcrumb__content--menu__items">
                                            <div className="text-white" onClick={(e) => handleBackToHome(e)}>
                                                Home
                                            </div>
                                        </li>
                                        <li className="breadcrumb__content--menu__items">
                                            <span className="text-white">Profile</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
               
              
                {/* End breadcrumb section */}

                <section className="section--padding  pt-5">
                    <div className="container">
                        {/* use class "saved" when the form is filled with save and replace it with the tick mark icon SVG.  */}
                        {/* Use class "active" when form is active */}
                        <div className="row">
                            <div className="col-sm-4 d-flex align-items-center justify-content-center my-0 pb-0"></div>
                            <div className="col-sm-4 d-flex align-items-center justify-content-center my-0 pb-0">
                                <ul className="event-topic">
                                    <a onClick={(e) => setSection('my-profile')}>
                                        <li className={((Section === 'my-profile') || (Section === 'social-media-accounts') || (Section === 'communication_settings') || (Section === 'general-details') || (Section === 'health-details') || (Section === 'edit-my-profile') || (Section === 'edit-social-media-accounts') || (Section === 'edit-communication_settings') || (Section === 'edit-general-details') || (Section === 'edit-health-details') || (Section === 'address') || (Section === 'edit-address') || (Section === 'family-members') || (Section === 'edit-family-members')) ? "event-topic-li active" : "event-topic-li"}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                height={22}
                                                viewBox="0 -960 960 960"
                                                width={22}
                                            >
                                                <path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z" />
                                            </svg>{" "}
                                            My Profile
                                        </li>
                                    </a>

                                    <a onClick={(e) => setSection('organizing-team')}>
                                        <li className={((Section === 'organizing-team') || (Section === 'new_user')) ? "event-topic-li active" : "event-topic-li"}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                height={24}
                                                viewBox="0 -960 960 960"
                                                width={24}
                                            >
                                                <path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z" />
                                            </svg>{" "}
                                            Organizing Team
                                        </li>
                                    </a>

                                </ul>


                            </div>
                            <div className="col-sm-4 d-flex align-items-center justify-content-end my-0 pb-0">
                            {EventAthletCard >= 3 ?
                            <div className="account__login--btn secondary__btn d-flex align-items-center gap-2 w-auto" title='Attendee Details Download' onClick={(e) => { setDownloadAthletcard(true)}} style={{cursor: 'pointer'}} >
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M260-160q-91 0-155.5-63T40-377q0-78 47-139t123-78q17-72 85-137t145-65q33 0 56.5 23.5T520-716v242l64-62 56 56-160 160-160-160 56-56 64 62v-242q-76 14-118 73.5T280-520h-20q-58 0-99 41t-41 99q0 58 41 99t99 41h480q42 0 71-29t29-71q0-42-29-71t-71-29h-60v-80q0-48-22-89.5T600-680v-93q74 35 117 103.5T760-520q69 8 114.5 59.5T920-340q0 75-52.5 127.5T740-160H260Zm220-358Z" /></svg> Athlete Card
                            </div>
                            : null }

                            </div>
                        </div>
                        <div className="row pt-5">
                            <div className={((Section === 'my-profile') || (Section === 'social-media-accounts') || (Section === 'communication_settings') || (Section === 'general-details') || (Section === 'health-details') || (Section === 'edit-my-profile') || (Section === 'edit-social-media-accounts') || (Section === 'edit-communication_settings') || (Section === 'edit-general-details') || (Section === 'edit-health-details') || (Section === 'address') || (Section === 'edit-address') || (Section === 'family-members') || (Section === 'edit-family-members')) ? "col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 d-md-2-none" : "col-lg-4 col-md-6 d-none"} >
                                <aside className="checkout__sidebar sidebar border-radius-10 pt-4">
                                    <div className="row justify-content-center py-0">
                                        <div className="card border-radius-10 mb-4">
                                            <div className="card-body p-2 " >
                                                <div className="row">
                                                    <div className="col-12 col-sm-4 col-md-4 col-lg-12 col-xl-12 col-xxl-4 my-3 text-center">
                                                        <img
                                                            className="user-img"
                                                            src={Userdata.profile_pic}
                                                            alt={Userdata.firstname + " " + Userdata.lastname}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-8 col-md-8 col-lg-12 col-xl-12 col-xxl-8 my-3 ">
                                                        <p className="m-2 h4">{Firstname + " " + Lastname} </p>
                                                        <p className="m-2">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                height={20}
                                                                viewBox="0 -960 960 960"
                                                                width={20}
                                                            >
                                                                <path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z" />
                                                            </svg> +91 - {Mobile}
                                                        </p>

                                                        <p className="m-2">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                height={20}
                                                                viewBox="0 -960 960 960"
                                                                width={20}
                                                            >
                                                                <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z" />
                                                            </svg> {Email}
                                                        </p>
                                                    </div>
                                                    <div className="col-sm-12 my-3 text-center">
                                                        <div className="progress-section" data-aos="fade-left" data-aos-once="true">
                                                            <div className="task-progress">
                                                                <p className='mb-3'>Profile Progress<span>{ProfileCompletionPercentage}%</span>
                                                                </p>
                                                                <progress className={ProgressbarClass !== "" ? ProgressbarClass : "progress progress1"} min={0} max={100} value={ProfileCompletionPercentage} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 mt-3">
                                            <h2 className="section__header--title h3">
                                                Your Information
                                            </h2>
                                            <hr />
                                            <div className={((Section === 'my-profile') || (Section === 'edit-my-profile')) ? "aside-btn p-4 my-4 aside-btn-active" : "aside-btn p-4 my-4"} onClick={(e) => { setSection("my-profile"); scrollToTop() }}>
                                                <div className="d-flex gap-3">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height={24}
                                                        viewBox="0 -960 960 960"
                                                        width={24}
                                                    >
                                                        <path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z" />
                                                    </svg>
                                                    <p>Personal Details</p>
                                                </div>
                                                <div>
                                                    {((Section === 'my-profile') || (Section === 'edit-my-profile')) ?
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" /></svg> :
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            height={24}
                                                            viewBox="0 -960 960 960"
                                                            width={24}
                                                        >
                                                            <path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" />
                                                        </svg>}
                                                </div>
                                            </div>

                                            <div className={((Section === 'general-details') || (Section === 'edit-general-details')) ? "aside-btn p-4 my-4 aside-btn-active" : "aside-btn p-4 my-4"} onClick={(e) => { setSection("general-details"); scrollToTop() }}>
                                                <div className="d-flex gap-3">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height={24}
                                                        viewBox="0 -960 960 960"
                                                        width={24}
                                                    >
                                                        <path d="M410-120v-238L204-239l-70-121 206-120-206-119 70-121 206 119v-239h140v239l206-119 70 121-206 119 206 120-70 121-206-119v238H410Z" />
                                                    </svg>
                                                    <p>Basic Information</p>
                                                </div>
                                                <div>
                                                    {((Section === 'general-details') || (Section === 'edit-general-details')) ?
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" /></svg>
                                                        :
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            height={24}
                                                            viewBox="0 -960 960 960"
                                                            width={24}
                                                        >
                                                            <path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" />
                                                        </svg>
                                                    }
                                                </div>
                                            </div>

                                            <div className={((Section === 'address') || (Section === 'edit-address')) ? "aside-btn p-4 my-4 aside-btn-active" : "aside-btn p-4 my-4"} onClick={(e) => { setSection("address"); scrollToTop() }}>
                                                <div className="d-flex gap-3">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height={24}
                                                        viewBox="0 -960 960 960"
                                                        width={24}
                                                    >
                                                        <path d="M360-440h80v-110h80v110h80v-190l-120-80-120 80v190Zm120 254q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z" />
                                                    </svg>
                                                    <p>Your Address</p>
                                                </div>
                                                <div>
                                                    {((Section === 'address') || (Section === 'edit-address')) ?
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" /></svg>
                                                        :
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            height={24}
                                                            viewBox="0 -960 960 960"
                                                            width={24}
                                                        >
                                                            <path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" />
                                                        </svg>
                                                    }
                                                </div>
                                            </div>

                                            {/* <div className={((Section === 'social-media-accounts') || Section === 'edit-social-media-accounts') ? "aside-btn p-4 my-4 aside-btn-active" : "aside-btn p-4 my-4"} onClick={(e) => { setSection("social-media-accounts"); scrollToTop() }}>
                                                <div className="d-flex gap-3">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height={24}
                                                        viewBox="0 -960 960 960"
                                                        width={24}
                                                    >
                                                        <path d="M160-40v-80h640v80H160Zm0-800v-80h640v80H160Zm320 400q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm70-80q45-56 109-88t141-32q77 0 141 32t109 88h70v-480H160v480h70Zm118 0h264q-29-20-62.5-30T480-280q-36 0-69.5 10T348-240Zm132-280q-17 0-28.5-11.5T440-560q0-17 11.5-28.5T480-600q17 0 28.5 11.5T520-560q0 17-11.5 28.5T480-520Zm0 40Z" />
                                                    </svg>
                                                    <p>Digital Presence</p>
                                                </div>
                                                <div>
                                                    {((Section === 'social-media-accounts') || Section === 'edit-social-media-accounts') ?
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" /></svg>
                                                        :
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            height={24}
                                                            viewBox="0 -960 960 960"
                                                            width={24}
                                                        >
                                                            <path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" />
                                                        </svg>
                                                    }
                                                </div>
                                            </div> */}


                                            <div className={((Section === 'health-details') || (Section === 'edit-health-details')) ? "aside-btn p-4 my-4 aside-btn-active" : "aside-btn p-4 my-4"} onClick={(e) => { setSection("health-details"); scrollToTop() }}>
                                                <div className="d-flex gap-3">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height={24}
                                                        viewBox="0 -960 960 960"
                                                        width={24}
                                                    >
                                                        <path d="M480-480Zm0 360q-18 0-34.5-6.5T416-146L148-415q-35-35-51.5-80T80-589q0-103 67-177t167-74q48 0 90.5 19t75.5 53q32-34 74.5-53t90.5-19q100 0 167.5 74T880-590q0 49-17 94t-51 80L543-146q-13 13-29 19.5t-34 6.5Zm40-520q10 0 19 5t14 13l68 102h166q7-17 10.5-34.5T801-590q-2-69-46-118.5T645-758q-31 0-59.5 12T536-711l-27 29q-5 6-13 9.5t-16 3.5q-8 0-16-3.5t-14-9.5l-27-29q-21-23-49-36t-60-13q-66 0-110 50.5T160-590q0 18 3 35.5t10 34.5h187q10 0 19 5t14 13l35 52 54-162q4-12 14.5-20t23.5-8Zm12 130-54 162q-4 12-15 20t-24 8q-10 0-19-5t-14-13l-68-102H236l237 237q2 2 3.5 2.5t3.5.5q2 0 3.5-.5t3.5-2.5l236-237H600q-10 0-19-5t-15-13l-34-52Z" />
                                                    </svg>
                                                    <p>Medical Profile</p>
                                                </div>
                                                <div>
                                                    {((Section === 'health-details') || (Section === 'edit-health-details')) ?
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" /></svg>
                                                        :
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            height={24}
                                                            viewBox="0 -960 960 960"
                                                            width={24}
                                                        >
                                                            <path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" />
                                                        </svg>
                                                    }
                                                </div>
                                            </div>

                                            {/* <div className={((Section === 'communication_settings') || (Section === 'edit-communication_settings')) ? "aside-btn p-4 my-4 aside-btn-active" : "aside-btn p-4 my-4"} onClick={(e) => { setSection("communication_settings"); scrollToTop() }}>
                                                <div className="d-flex gap-3">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height={24}
                                                        viewBox="0 -960 960 960"
                                                        width={24}
                                                    >
                                                        <path d="M240-400h320v-80H240v80Zm0-120h480v-80H240v80Zm0-120h480v-80H240v80ZM80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240L80-80Zm126-240h594v-480H160v525l46-45Zm-46 0v-480 480Z" />
                                                    </svg>
                                                    <p>Communication Settings</p>
                                                </div>
                                                <div>
                                                    {((Section === 'communication_settings') || (Section === 'edit-communication_settings')) ?
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            height={24}
                                                            viewBox="0 -960 960 960"
                                                            width={24}
                                                        >
                                                            <path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" />
                                                        </svg>
                                                        :
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" /></svg>
                                                    }
                                                </div>
                                            </div> */}

                                            {/* <div className={((Section === 'family-members') || (Section === 'edit-family-members')) ? "aside-btn p-4 my-4 aside-btn-active" : "aside-btn p-4 my-4"} onClick={(e) => { setSection("family-members"); scrollToTop() }}>
                                                <div className="d-flex gap-3">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height={24}
                                                        viewBox="0 -960 960 960"
                                                        width={24}
                                                    >
                                                        <path d="M240-400h320v-80H240v80Zm0-120h480v-80H240v80Zm0-120h480v-80H240v80ZM80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240L80-80Zm126-240h594v-480H160v525l46-45Zm-46 0v-480 480Z" />
                                                    </svg>
                                                    <p>Family Members</p>
                                                </div>
                                                <div>
                                                    {((Section === 'family-members') || (Section === 'edit-family-members')) ?
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            height={24}
                                                            viewBox="0 -960 960 960"
                                                            width={24}
                                                        >
                                                            <path d="M480-280q83 0 141.5-58.5T680-480q0-83-58.5-141.5T480-680q-83 0-141.5 58.5T280-480q0 83 58.5 141.5T480-280Zm0 200q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                                                        </svg>
                                                        :
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                                                    }
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </aside>
                            </div>

                            {/* proflie-card-show */}

                            <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 ">
                                {/* SHOW USER PROFILE */}
                                <MyProfile Tab={Section} setTab={setSection} />


                                {/* EDIT PROFILE */}
                                {((Section === 'edit-my-profile') || (Section === 'edit-social-media-accounts') || (Section === 'edit-communication_settings') || (Section === 'edit-general-details') || (Section === 'edit-health-details') || (Section === 'edit-address') || (Section === 'edit-family-members')) ?
                                    <EditMyProfile Tab={Section} setSection={setSection} ProfileApiCall={ProfileApiCall} setProfileApiCall={setProfileApiCall} Userdata={Userdata} setLoader={setLoader} loader={loader} />
                                    :
                                    null}
                                {/* END EDIT PROFILE */}
                                
                                {
                                    Section === 'new_user' ?
                                        <NewUser />
                                        :
                                        null
                                }
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 ">

                                {/* ORGANIZING TEAM */}
                                {
                                    Section === 'organizing-team' ?
                                        <NewOrgUser Tab={Section} setSection={setSection} />
                                        :
                                        // <OrganizingTeam Tab={Section} setSection={setSection} />
                                        null
                                }

                            </div>


                        </div>
                    </div>
                </section>

                {/* Scroll top bar */}
                {isVisible ?
                    <button
                        aria-label="Scroll to top"
                        id="scroll__top"
                        className={isVisible ? 'show' : 'hide'}
                        onClick={scrollToTop}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M12 19V5M5 12l7-7 7 7" />
                        </svg>
                    </button>
                    : null}
                {/* End Scroll top bar */}
            </main>
        </>

    )
}

export default Profile